/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  useEffect
} from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Radio, Select, Upload } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'

import {
  Content,
  AnimationContainer,
  DatePicker,
  Container,
  ContentInput,
  InputColumn,
  Wrapper,
  WrapperDoc
} from './styles'

import Button from '../../components/Button'
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'

import DateFnsUtils from '@date-io/date-fns'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptLocale from 'date-fns/locale/pt-BR'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { cepMask, cpfMask, phoneMask } from 'utils'
import Input from 'components/Input'
import getValidationErrors from 'utils/getValidationErrors'
import formatValue from 'utils/formatValue'
import Loading from 'components/Loading'
import { AiOutlineUpload } from 'react-icons/ai'
import { info } from 'pages/UploadPessoaFisica/ModalInfo'
import { UploadChangeParam } from 'antd/lib/upload'

interface RegisterUserFormData {
  UserId: number
  BirthDate: string | null
  AlternativeEmail: string
  Rg: string
  Gender: string
  Phone: string
  CellPhone: string
  CivilStatus: string
  Name: string
  cpf: string
  Email: string
  CustomerId: number
  Address: {
    ZipCode: string
    Street: string
    Number: number
    District: string
    City: string
    State: string
    Complement: string
  }
  Job: {
    Company: string
    Office: string
    Salary: string
    FunctionalRelationship: string
    Phone: string
    AdmissionDate: null
  }
  Spouse: {
    Name: string
    Cpf: string
    SpouseMakesUpIncome: boolean
    Email: string | null
    Phone: string | null
    Salary: string | null | number
    Company: string
    PositionWork: string
    BirthDate: string | null
    AdmissionDate: string | null
  } | null
}

const RegisterCompany: React.FC = () => {
  const { infoClient, orderId } = useParams<any>()

  const userIdPf = Number(localStorage.getItem('@FleetSolutions:userIdPf'))
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()
  const dispatch = useDispatch()

  const [loadingDocInco, setLoadingDocInco] = useState(false)

  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [isStreetEnabled, setStreetEnabled] = useState(false)
  const [isCityEnabled, setCityEnabled] = useState(false)
  const [isStateEnabled, setStateEnabled] = useState(false)
  const [stateUser, setStateUser] = useState('')
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [salary, setSalary] = useState('')
  const [birthDate, setBirthDate] = useState<string | null>(null)
  const [user, setUser] = useState({} as any)
  const [customer, setCustomers] = useState({} as any)
  const [admissionDate, setAdmissionDate] = useState(null)
  const [rg, setRg] = useState('')
  const [genderOptions, setGenderOptions] = useState([])
  const [selectedGender, setselectedGender] = useState('Selecione o gênero *')
  const [civilStatus, setCivilStatus] = useState<any>(
    'Selecione o estado civil  *'
  )
  const [civilStatusOptions, setCivilStatusOptions] = useState([])
  const [jobPhone, setJobPhone] = useState('')
  const [customerId, setCustomerId] = useState(0)
  const [isSpouse, setIsSpouse] = useState(false)
  const [nameSpouse, setNameSpouse] = useState('')
  const [spouseEmail, setSpouseEmail] = useState('')
  const [spousePhone, setSpousePhone] = useState('')
  const [spouseSalary, setSpouseSalary] = useState('')
  const [spouseCompany, setSpouseCompany] = useState('')
  const [spousePositionWork, setSpousePositionWork] = useState('')
  const [spouseBirthDate, setSpouseBirthDate] = useState<string | null>(null)
  const [spouseAdmissionDate, setSpouseAdimissionDate] = useState<
    string | null
  >(null)

  const [isUploadDocument, setIsUploadDocument] = useState(false)

  const [cpfSpouse, setCpfSpouse] = useState('')

  const [statesBr, setStatesBr] = useState<any[]>([])
  const [locationStates, setLocationStates] = useState<any[]>([])
  const [citiesBr, setCitiesBr] = useState([])

  const initialData = {
    Name: user.fullname,
    cpf: user.cpfCnpj,
    UserId: user.userId,
    AlternativeEmail: customer.alternativeEmail,
    BirthDate: birthDate,
    Rg: customer.rg,
    Gender: customer.gender,
    Phone: customer.phone,
    CellPhone: customer.cellPhone,
    CivilStatus: customer.civilStatus,
    Email: user.email,
    Address: {
      ZipCode: !isEmpty(customer.address) ? customer.address.zipCode : '',
      City: !isEmpty(customer.address) ? customer.address.city : '',
      Complement: !isEmpty(customer.address) ? customer.address.complement : '',
      District: !isEmpty(customer.address) ? customer.address.district : '',
      Number: !isEmpty(customer.address) ? customer.address.number : '',
      Street: !isEmpty(customer.address) ? customer.address.street : '',
      State: !isEmpty(customer.address) ? customer.address.state : ''
    },
    Job: {
      Name: !isEmpty(customer.job) ? customer.job.name : '',
      Salary: !isEmpty(customer.job) ? formatValue(customer.job.salary) : '',
      Company: !isEmpty(customer.job) ? customer.job.company : '',
      Position: !isEmpty(customer.job) ? customer.job.position : '',
      Phone: !isEmpty(customer.job) ? customer.job.phone : '',
      AdmissionDate: admissionDate
    },
    Spouse: {
      Name: !isEmpty(customer.spouse) ? customer.spouse.name : '',
      Cpf: !isEmpty(customer.spouse) ? customer.spouse.cpf : '',
      SpouseMakesUpIncome: !isEmpty(customer.spouse)
        ? customer.spouse.spouseMakesUpIncome
        : '',
      Email: !isEmpty(customer.spouse) ? customer.spouse.email : '',
      Phone: !isEmpty(customer.spouse) ? customer.spouse.phone : '',
      Salary: !isEmpty(customer.spouse)
        ? formatValue(customer.spouse.salary)
        : '',
      Company: !isEmpty(customer.spouse) ? customer.spouse.company : '',
      PositionWork: !isEmpty(customer.spouse)
        ? customer.spouse.positionWork
        : '',
      BirthDate: spouseBirthDate,
      AdmissionDate: spouseAdmissionDate
    }
  }

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info: UploadChangeParam) {
      if (info.file.type)
        info.file.name = `Comprovante de renda.${info.file.type.split('/')[1]}`
      setIsUploadDocument(false)
      handleFormatDocUpload(info)
    }
  }

  const handleUploadButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
  }

  const handleFormatDocUpload = async (image: any) => {
    const formData = new FormData()

    if (image.file.size > 5000000 && image.file.status !== 'uploading') {
      return addToast({
        title: 'Falha ao enviar documento',
        type: 'error',
        description: 'O tamanho do arquivo é maior que 5 mb'
      })
    } else {
      if (image.file.status === 'done' || image.file.status === 'error') {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Accept: 'text/plain'
          }
        }
        formData.append('File', image.file.originFileObj)
        formData.append('UserId', userIdPf.toString())
        formData.append('FileType', 'Others')
        // formData.append('uploadRequest', image.file.originFileObj)
        await sendInco(config, formData)
      }
    }
  }

  const sendInco = async (config: any, formData: any) => {
    setLoadingDocInco(true)
    try {
      await api
        .post(
          `/newdocuments`,
          formData,
          config
        )
        .then((response: any) => {
          localStorage.setItem(
            '@FleetSolutions:uploadFileDocumentoUser',
            'true'
          )
          setIsUploadDocument(true)
          setLoadingDocInco(false)
        })
    } catch (error) {
      addToast({ 
        title: 'Erro ao fazer upload', 

        description: 'Ocorreu um erro ao fazer upload do comprovante de renda.',
        type: 'error'
      })
      setLoadingDocInco(false)
    }
  }

  useEffect(() => {
    const element = document.querySelector('.MuiFormControl-marginDense')
    if (element) {
      element.classList.remove('MuiFormControl-marginDense')
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const fetchStates = async () => {
      try {
        // Fetch estados do IBGE
        const res = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        const data = await res.json();
        
        // Fetch estados da API
        const response = await api.get('location/state');
        setLocationStates(response.data);

        // Ordenar estados por nome
        const sortedData = data.sort((a: { nome: string }, b: { nome: any }) => a.nome.localeCompare(b.nome));
        setStatesBr(sortedData);

        // Verificar se customer e customer.address estão definidos
        if (customer && customer.address) {
          // Encontrar UF do cliente
          const ufName = response.data.find((item: { value: any }) => item.value === customer.address.state)?.text;
          const ufCode = sortedData.find((state: { nome: any }) => state.nome === ufName)?.sigla;
          setStateUser(ufCode);
        }

      } catch (err) {
        console.error(err);
      }
    };

    if (statesBr.length === 0) {
      fetchStates();
    }
  }, [statesBr.length, customer]);

  // useEffect(() => {
  //   const userUploadFileDocument = Boolean(
  //     localStorage.getItem('@FleetSolutions:uploadFileDocumentoUser')
  //   )
  //   setIsUploadDocument(userUploadFileDocument)
  // }, [])

  useEffect(() => {
    if (infoClient) {
      api.get('/genderenum').then((response: any) => {
        setGenderOptions(response.data)
      })

      api.get('/civilstatusenum').then((response: any) => {
        setCivilStatusOptions(response.data)
      })

      api
        .get(`/users/${!orderId ? userIdPf : infoClient}`)
        .then((response: any) => {
          const { fullname, email, cpfCnpj } = response.data

          setCpf(cpfMask(cpfCnpj))

          if (fullname.includes('Client-') || email.includes('client_')) {
            response.data.fullname = ''
            response.data.email = ''
          }
          setUser(response.data)
        })

      api
        .get(`/customers/${!orderId ? userIdPf : infoClient}`)
        .then((response: any) => {
          if (!response.data.cellPhone) {
            response.data.gender = ''
            response.data.civilStatus = ''
          }
          setCustomers(response.data)
        })
    }
  }, [infoClient])

  useEffect(() => {
    if (!isEmpty(customer)) {
      if (!isEmpty(customer.job)) {
        setAdmissionDate(customer.job.admissionDate)
        setSalary(customer.job.salary)
        setJobPhone(phoneMask(customer.job.phone))
      }
      if (!isEmpty(customer.address)) {
        const ufName = locationStates.filter((item:any) => item.value === customer.address.state)[0]?.text
        const ufCode = statesBr.filter((state:any) => state.nome === ufName)[0]?.sigla

        setStateUser(ufCode)

        setCep(cepMask(customer.address.zipCode))
        setStreet(customer.address.street)
        setDistrict(customer.address.district)
        customer.address.city
          ? setCity(customer.address.city)
          : setCityEnabled(true)

        // api
        //   .get(`/ceps?cep=${customer.address.zipCode}`)
        //   .then((response: any) => {
        //     if (!response.data.erro) {
        //       response.data.uf
        //         ? setStateUser(response.data.uf)
        //         : setStateEnabled(true)
        //     }
        //   })
      }
      setBirthDate(customer.birthDate)
      setCivilStatus(customer.civilStatus)
      setCustomerId(customer.customerId)
      setselectedGender(customer.gender)
      setCellPhone(phoneMask(customer.cellPhone))
      setPhone(phoneMask(customer.phone))
      setRg(customer.rg)

      if (customer.spouse !== null) {
        setNameSpouse(customer.spouse.name)
        setSpousePhone(phoneMask(customer.spouse.phone))
        setCpfSpouse(cpfMask(customer.spouse.cpf))
        setIsSpouse(customer.spouse.spouseMakesUpIncome)
        setSpouseSalary(customer.spouse.salary)
        setSpouseEmail(customer.spouse.email)
        setSpouseCompany(customer.spouse.company)
        setSpousePositionWork(customer.spouse.positionWork)
        setSpouseBirthDate(customer.spouse.birthDate)
        setSpouseAdimissionDate(customer.spouse.admissionDate)
      }
    }
  }, [customer, user])

  const handleSubmit = useCallback(
    async (data: RegisterUserFormData) => {
      
      if (civilStatus === 2 || civilStatus === 5) {
        if (!isUploadDocument && isSpouse) {
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'O comprovante de renda é um documento obrigatório.'
          })
          return
        }
      }

      if (birthDate !== null) {
        const yearBirthdate = new Date(birthDate).getFullYear()
        const yearCurrent = new Date().getFullYear()

        if (yearCurrent - yearBirthdate < 18) {
          addToast({
            title: 'Erro ao cadastrar',
            type: 'error',
            description:
              'O aluguel de veículos é permitido apenas para maiores de idade.'
          })
          return
        }
      }

      if (selectedGender === 'Selecione o gênero') {
        addToast({
          title: 'Campo obrigatório',
          type: 'error',
          description: 'Selecione seu gênero'
        })
        return
      }

      if (civilStatus === 'Selecione o estado civil') {
        addToast({
          title: 'Campo obrigatório',
          type: 'error',
          description: 'Selecione seu estado civil'
        })
        return
      }

      if (isSpouse) {
        if (!nameSpouse) {
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'Informe o nome do cônjuge'
          })
          return
        }

        if (!cpfSpouse) {
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'Informe o CPF do cônjuge'
          })
          return
        }

        if (civilStatus === 2 || civilStatus === 5) {
          if (!spousePositionWork) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe o Cargo do cônjuge'
            })
            return
          }

          if (!spouseCompany) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe a Empresa do cônjuge'
            })
            return
          }

          if (!spouseBirthDate) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe a Data de Nascimento do cônjuge'
            })
            return
          }

          if (!spouseAdmissionDate) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe a Data de Admissão do cônjuge'
            })
            return
          }

          if (!spouseSalary) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe o Salário do cônjuge'
            })
            return
          }
        }

        data.Spouse = {
          Name: nameSpouse,
          Email: spouseEmail,
          SpouseMakesUpIncome: isSpouse,
          Phone: convertoToNumberOnly(spousePhone),
          Cpf: cpfSpouse.replace(/\D/g, ''),
          Salary: spouseSalary,
          AdmissionDate: spouseAdmissionDate,
          BirthDate: spouseBirthDate,
          Company: spouseCompany,
          PositionWork: spousePositionWork
        }
      } else {
        if (civilStatus === 2 || civilStatus === 5) {
          if (!nameSpouse) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe o nome do cônjuge'
            })
            return
          }

          if (!cpfSpouse) {
            addToast({
              title: 'Campo obrigatório',
              type: 'error',
              description: 'Informe o CPF do cônjuge'
            })
            return
          }

          data.Spouse = {
            Name: nameSpouse,
            Email: '',
            SpouseMakesUpIncome: isSpouse,
            Phone: '',
            Cpf: cpfSpouse.replace(/\D/g, ''),
            Salary: 0,
            AdmissionDate: null,
            BirthDate: null,
            Company: '',
            PositionWork: ''
          }
        }
      }

      data.UserId = !orderId ? userIdPf : infoClient
      data.Gender = selectedGender
      data.CivilStatus = civilStatus
      data.Address.State = stateUser
      data.Address.City = city
      data.BirthDate = birthDate
      data.Phone = convertoToNumberOnly(phone)
      data.CellPhone = convertoToNumberOnly(cellPhone)
      data.Job.Phone = convertoToNumberOnly(jobPhone)
      data.Job.AdmissionDate = admissionDate
      data.Job.Salary = salary
      data.Address.ZipCode = convertoToNumberOnly(cep)
      data.Rg = rg
      data.cpf = cpf
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          Name: Yup.string().required('Nome obrigatório'),
          Rg: Yup.string().required('Campo obrigatório'),
          Phone: Yup.string()
            .required('Campo obrigatório')
            .min(10, 'Telefone inválido')
            .max(10, 'Telefone inválido'),
          CellPhone: Yup.string()
            .required('Campo obrigatório')
            .min(11, 'Celular inválido'),
          BirthDate: Yup.date().required(''),
          Email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          AlternativeEmail: Yup.string().email('Digite um e-mail válido'),
          Gender: Yup.number().required('Campo obrigatório'),
          CivilStatus: Yup.string().required('Campo obrigatório'),
          Address: Yup.object().shape({
            ZipCode: Yup.string()
              .required('Campo obrigatório')
              .min(8, 'CEP inválido')
              .max(8, 'CEP inválido'),
            Street: Yup.string().required('Campo obrigatório'),
            Number: Yup.string().required('Campo obrigatório'),
            District: Yup.string().required('Campo obrigatório'),
            City: Yup.string().required('Campo obrigatório'),
            State: Yup.string().required('Campo obrigatório')
          }),
          Job: Yup.object().shape({
            Company: Yup.string().required('Campo obrigatório'),
            Phone: Yup.number().required('Campo obrigatório').min(10, 'Telefone inválido'),
            AdmissionDate: Yup.date().required('Campo obrigatório'),
            Position: Yup.string().required('Campo obrigatório'),
            Salary: Yup.string().required('Campo obrigatório')
          })
        })
        await schema.validate(data, {
          abortEarly: false
        })

        data.cpf = data.cpf.replace(/\D/g, '')

        data.Email = data.Email.toLowerCase()

        if (isEmpty(customer)) {
          await api
            .post('/customers', data)
            .then(() => {
              addToast({
                title: 'Cadastro realizado',
                type: 'success',
                description: 'Seu cadastro foi realizado com sucesso.'
              })
              setIsUploadDocument(false)
              dispatch(setTabs({ id: '3' }))
            })
            .catch((error) => {
              if (error.response.data.errors['Spouse.Cpf']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o cpf do cônjuge'
                })
              }
              if (error.response.data.errors['Job.Phone']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o telefone'
                })
              }
              if (error.response.data.errors['Spouse.PositionWork']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o Cargo'
                })
              }
              if (error.response.data.errors['Spouse.Company']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Empresa'
                })
              }
              if (error.response.data.errors['Spouse.BirthDate']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Data de Nascimento'
                })
              }
              if (error.response.data.errors['Spouse.AdmissionDate']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Data de Admissão'
                })
              }
              if (error.response.data.errors.Cpf) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description:
                    'O CPF do cônjugue deve ser diferente do usuário cadastrado'
                })
              }
            })
        } else {
          data.CustomerId = customerId
          await api
            .put(`/customers?userId=${!orderId ? userIdPf : infoClient}`, data)
            .then(async () => {
              setIsUploadDocument(false)
              if (orderId) {
                await api.put('/orders/RetryCreditAnalysis', {
                  orderId: Number(orderId),
                  userId: Number(infoClient)
                })

                history.push(`/order-status-pf/${orderId}`)
              } else {
                addToast({
                  title: 'Cadastro editado',
                  type: 'success',
                  description: 'Seu cadastro foi editado com sucesso.'
                })
                dispatch(setTabs({ id: '3' }))
              }
            })
            .catch((error) => {
              if (error.response.data.errors['Spouse.Cpf']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o cpf do cônjuge'
                })
              }
              if (error.response.data.errors['Spouse.PositionWork']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o Cargo'
                })
              }
              if (error.response.data.errors['Spouse.Company']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Empresa'
                })
              }
              if (error.response.data.errors['Spouse.BirthDate']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Data de Nascimento'
                })
              }
              if (error.response.data.errors['Spouse.AdmissionDate']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe a Data de Admissão'
                })
              }
              if (error.response.data.errors['Job.Phone']) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description: 'Informe o telefone'
                })
              }
              if (error.response.data.errors.Cpf) {
                addToast({
                  type: 'error',
                  title: 'Campo obrigatório',
                  description:
                    'O CPF do cônjugue deve ser diferente do usuário cadastrado'
                })
              }
            })
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
          addToast({
            type: 'error',
            title: 'Erro ao Cadastrar',
            description: 'Preencha os campos corretamente.'
          })
        }
      }
    },
    [
      addToast,
      dispatch,
      history,
      cep,
      salary,
      jobPhone,
      rg,
      phone,
      cellPhone,
      civilStatus,
      selectedGender,
      spousePhone,
      cpfSpouse,
      spouseSalary,
      nameSpouse,
      spouseEmail,
      birthDate,
      isSpouse,
      admissionDate,
      spouseCompany,
      spousePositionWork,
      spouseAdmissionDate,
      spouseBirthDate,
      isUploadDocument,
      stateUser
    ]
  )

  const handleKeyUpCep = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCep(cepMask(value))
    if (value.length === 9) {
      api
        .get(`/ceps?cep=${e.target.value}`)
        .then((response: any) => {
          if (!response.data.erro) {
            response.data.logradouro
              ? setStreet(response.data.logradouro)
              : setStreetEnabled(true)
            setDistrict(response.data.bairro)
            response.data.localidade
              ? setCity(response.data.localidade)
              : setCityEnabled(true)
            response.data.uf
              ? setStateUser(response.data.uf)
              : setStateEnabled(true)
          }
        })
        .catch((error: any) => {
          setStreet('')
          setDistrict('')
          setCity('')
          setStateUser('')
          setStreetEnabled(true)
          setCityEnabled(true)
          setStateEnabled(true)
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'Endereço não encontrado'
          })
        })
    }
  }

  const handleFormatCpf = (item: any) => {
    setCpf(cpfMask(item.target.value))
  }

  const handleFormatPhone = (item: any) => {
    setPhone(phoneMask(item.target.value))
  }

  const handleFormatJobPhone = (item: any) => {
    setJobPhone(phoneMask(item.target.value))
  }

  const handleFormatCellPhone = (item: any) => {
    setCellPhone(phoneMask(item.target.value))
  }

  const handleKeyUpRg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRg(convertoToNumberOnly(e.target.value))
  }, [])

  const formatCurrency = (value: any) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{2})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')

    return 'R$ ' + value
  }

  const convertoToNumberOnly = (value: any) => {
    return value.replace(/([^\d])+/gim, '')
  }

  const handleChangeSalary = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = formatCurrency(e.currentTarget.value)
    setSalary(convertoToNumberOnly(e.currentTarget.value).slice(0, -2))
  }, [])

  const handleSelectGender = (event: any) => {
    setselectedGender(event)
  }

  const handleSelectCivilStatus = (event: any) => {
    setCivilStatus(event)
  }

  const handleKeyUpNameSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNameSpouse(e.target.value)
    },
    []
  )

  const handleKeyUpCompanySpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpouseCompany(e.target.value)
    },
    []
  )

  const handleKeyUpOfficeSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpousePositionWork(e.target.value)
    },
    []
  )

  const handleKeyUpEmailSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpouseEmail(e.target.value)
    },
    []
  )

  const handleKeyUpCpfSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCpfSpouse(cpfMask(e.target.value))
    },
    []
  )

  const handleChangeSpouseSalary = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget.value = formatCurrency(e.currentTarget.value)
      setSpouseSalary(convertoToNumberOnly(e.currentTarget.value).slice(0, -2))
    },
    []
  )

  const handleKeyUpPhoneSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpousePhone(phoneMask(convertoToNumberOnly(e.target.value)))
    },
    []
  )

  const handleChangeJobAdmissionDate = (date: any) => {
    setAdmissionDate(date)
  }

  const handleChangeSpouseAdmissionDate = (date: any) => {
    setSpouseAdimissionDate(date)
  }

  const handleChangeBirthDate = (date: any) => {
    setBirthDate(date)
  }

  const handleChangeSpouseBirthDate = (date: any) => {
    setSpouseBirthDate(date)
  }

  const handleKeyUpStreet = (item: any) => {
    setStreet(item.target.value)
  }

  const handleKeyUpDistrict = (item: any) => {
    setDistrict(item.target.value)
  }

  const handleKeyUpCity = (item: any) => {
    setCity(item.target.value)
  }

  const handleBackPage = () => {
    dispatch(setTabs({ id: '1' }))
  }

  const handleChangeState = (value: string) => {
    setStateUser(value)
    setCity('')
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    fetch(url)
      .then((res) =>
        res.json().then(function (data) {
          setCitiesBr(data)
        })
      )
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCityChange = (value: string) => {
    console.log(value)
    setCity(value)
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h1>CADASTRO DE PESSOA FÍSICA</h1>
            <p>
              Preencha o cadastro abaixo para realizar a reserva do veículo:
            </p>
            <span className='required-infos'>* Campos obrigatórios</span>
            <Form
              initialData={initialData}
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <ContentInput>
                <h2>Dados Pessoais</h2>
                <InputColumn>
                  <Input
                    placeholder="CPF"
                    id="cpf"
                    name="cpf"
                    type="text"
                    maxLength={18}
                    value={cpf}
                    disabled={true}
                    onChange={handleFormatCpf}
                  />
                  <Input
                    placeholder="Nome Completo *"
                    id="Name"
                    name="Name"
                    maxLength={80}
                    required
                    type="text"
                  />
                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="RG *"
                    value={rg}
                    id="Rg"
                    name="Rg"
                    type="text"
                    maxLength={13}
                    onChange={handleKeyUpRg}
                  />
                  <Input
                    placeholder="Telefone *"
                    id="Phone"
                    maxLength={14}
                    name="Phone"
                    type="tel"
                    value={phone}
                    onChange={handleFormatPhone}
                  />
                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="Celular *"
                    id="CellPhone"
                    name="CellPhone"
                    type="tel"
                    maxLength={15}
                    value={cellPhone}
                    onChange={handleFormatCellPhone}
                  />
                  <Input
                    placeholder="E-mail *"
                    id="Email"
                    name="Email"
                    maxLength={80}
                    required
                    type="text"
                  />
                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="E-mail alternativo *"
                    id="AlternativeEmail"
                    name="AlternativeEmail"
                    type="email"
                    maxLength={80}
                  />

                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptLocale}
                  >
                    <DatePicker
                      invalidDateMessage={'Data inválida'}
                      minDateMessage={
                        'A data não deve ser anterior à data mínima'
                      }
                      maxDateMessage={
                        'A data não deve ser posterior à data máxima'
                      }
                      variant="inline"
                      inputVariant="outlined"
                      className="birthday"
                      placeholder="Data de nascimento *"
                      margin="dense"
                      id="BirthDate"
                      name="BirthDate"
                      format="dd/MM/yyyy"
                      value={birthDate}
                      onChange={handleChangeBirthDate}
                    />
                  </MuiPickersUtilsProvider>
                </InputColumn>
                <InputColumn>
                  <div className="custom-selects">
                    <Select
                      size="large"
                      onChange={handleSelectGender}
                      value={selectedGender}
                    >
                      {genderOptions.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        )
                      })}
                    </Select>
                  </div>

                  <div className="custom-selects">
                    <Select
                      size="large"
                      onChange={handleSelectCivilStatus}
                      value={civilStatus}
                    >
                      {civilStatusOptions.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                </InputColumn>

                {(civilStatus === 2 || civilStatus === 5) && (
                  <>
                    <Wrapper>
                      <p>Cônjuge compõe renda?</p>
                      <Radio.Group
                        style={{ width: 'auto', marginLeft: 20 }}
                        value={isSpouse ? 1 : 2}
                        onChange={(e) => setIsSpouse(e.target.value === 1)}
                      >
                        <Radio value={1}>Sim</Radio>
                        <Radio value={2}>Não</Radio>
                      </Radio.Group>
                    </Wrapper>
                    {isSpouse ? (
                      <>
                        <InputColumn>
                          <Input
                            placeholder="Nome cônjuge *"
                            id="nameSpouse"
                            name="Spouse.Name"
                            type="text"
                            maxLength={80}
                            value={nameSpouse}
                            onChange={handleKeyUpNameSpouse}
                          />

                          <Input
                            placeholder="Cargo *"
                            id="officeSpouse"
                            name="Spouse.PositionWork"
                            type="text"
                            maxLength={80}
                            value={spousePositionWork}
                            onChange={handleKeyUpOfficeSpouse}
                          />
                        </InputColumn>
                        <InputColumn>
                          <Input
                            placeholder="CPF *"
                            id="spouseCpf"
                            name="Spouse.Cpf"
                            type="text"
                            maxLength={18}
                            value={cpfSpouse}
                            onChange={handleKeyUpCpfSpouse}
                          />

                          <Input
                            placeholder="Empresa *"
                            id="companySpouse"
                            name="Spouse.Company"
                            type="text"
                            maxLength={80}
                            value={spouseCompany}
                            onChange={handleKeyUpCompanySpouse}
                          />
                        </InputColumn>
                        <InputColumn>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptLocale}
                          >
                            <DatePicker
                              invalidDateMessage={'Data inválida'}
                              minDateMessage={
                                'A data não deve ser anterior à data mínima'
                              }
                              maxDateMessage={
                                'A data não deve ser posterior à data máxima'
                              }
                              variant="inline"
                              inputVariant="outlined"
                              className="birthday"
                              placeholder="Data de nascimento *"
                              margin="dense"
                              id="SpouseBirthDate"
                              name="SpouseBirthDate"
                              format="dd/MM/yyyy"
                              value={spouseBirthDate}
                              onChange={handleChangeSpouseBirthDate}
                            />
                          </MuiPickersUtilsProvider>

                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptLocale}
                          >
                            <DatePicker
                              invalidDateMessage={'Data inválida'}
                              minDateMessage={
                                'A data não deve ser anterior à data mínima'
                              }
                              maxDateMessage={
                                'A data não deve ser posterior à data máxima'
                              }
                              variant="inline"
                              inputVariant="outlined"
                              className="birthday"
                              placeholder="Data de admissão *"
                              margin="dense"
                              id="SpouseAdmissionDate"
                              name="SpouseAdmissionDate"
                              format="dd/MM/yyyy"
                              value={spouseAdmissionDate}
                              onChange={handleChangeSpouseAdmissionDate}
                            />
                          </MuiPickersUtilsProvider>
                        </InputColumn>
                        <InputColumn>
                          <Input
                            id="spouseSalary"
                            name="Spouse.Salary"
                            prefix="R$"
                            placeholder="Salário cônjuge *"
                            onChange={handleChangeSpouseSalary}
                          />
                          {/* <Input
                          placeholder="Telefone"
                          id="phoneSpouse"
                          name="Spouse.Phone"
                          type="tel"
                          onChange={handleKeyUpPhoneSpouse}
                          maxLength={15}
                          value={spousePhone}
                        />
                        <Input
                          placeholder="Email cônjuge"
                          id="emailSpouse"
                          name="Spouse.Email"
                          type="email"
                          maxLength={80}
                          onChange={handleKeyUpEmailSpouse}
                        /> */}
                        </InputColumn>
                        <h2>Comprovante de renda *</h2>
                        <WrapperDoc>
                          <div className="left">
                            <p className="name-file">Comprovante de renda</p>
                            <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                            <p>
                              <a
                                className="know-more"
                                href="#"
                                onClick={() =>
                                  info(
                                    'Comprovante de renda',
                                    'Cliente CLT: Holerite emitido há no máximo 60 dias. Para os casos de clientes com renda variável (horas extras, comissões e prêmios), é necessária a apresentação dos últimos 3 comprovantes de renda, sendo considerada a média. Isto evitará que picos de remuneração sejam considerados como habitual.<br/><br/>Proprietário Empresa: IRPF com o recibo de entrega da Declaração, do exercício atual, conforme calendário da Receita Federal (extrato detalhado do documento), ou extrato bancário dos últimos 90 dias.<br/><br/>Autônomo / Profissional liberal: IRPF com o recibo de entrega da Declaração, do exercício atual, conforme calendário da Receita Federal (extrato detalhado do documento), ou extrato bancário dos últimos 90 dias.'
                                  )
                                }
                              >
                                (saiba mais)
                              </a>
                            </p>
                          </div>
                          <div className="right">
                            <Upload className="upload" {...props}>
                              <div
                                style={
                                  loadingDocInco
                                    ? { display: '' }
                                    : { display: 'none' }
                                }
                              >
                                <Loading
                                  text={'Carregando comprovante de renda'}
                                />
                              </div>
                              {!loadingDocInco && (
                                <Button
                                  onClick={(
                                    e: React.MouseEvent<
                                      HTMLButtonElement,
                                      MouseEvent
                                    >
                                  ) => handleUploadButtonClick(e)}
                                  className="btn-upload"
                                >
                                  <AiOutlineUpload size={17} /> CLIQUE PARA
                                  CARREGAR
                                </Button>
                              )}
                            </Upload>
                          </div>
                        </WrapperDoc>
                      </>
                    ) : (
                      <InputColumn>
                        <Input
                          placeholder="Nome cônjuge *"
                          id="nameSpouse"
                          name="Spouse.Name"
                          type="text"
                          maxLength={80}
                          value={nameSpouse}
                          onChange={handleKeyUpNameSpouse}
                        />

                        <Input
                          placeholder="CPF *"
                          id="spouseCpf"
                          name="Spouse.Cpf"
                          type="text"
                          maxLength={18}
                          value={cpfSpouse}
                          onChange={handleKeyUpCpfSpouse}
                        />
                      </InputColumn>
                    )}
                  </>
                )}

                <h2>Endereço</h2>
                <InputColumn>
                  <Input
                    placeholder="CEP *"
                    id="zipcode"
                    name="Address.ZipCode"
                    type="text"
                    maxLength={9}
                    onChange={handleKeyUpCep}
                    value={cep}
                  />
                </InputColumn>
                <InputColumn>
                <Input
                    placeholder="Logradouro *"
                    id="street"
                    name="Address.Street"
                    type="text"
                    maxLength={80}
                    onChange={handleKeyUpStreet}
                    value={street}
                  />
                  <Input
                    placeholder="Número *"
                    id="text"
                    name="Address.Number"
                    type="number"
                    maxLength={5}
                  />

                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="Bairro *"
                    id="district"
                    name="Address.District"
                    type="text"
                    value={district}
                    onChange={handleKeyUpDistrict}
                  />
                  <Input
                    placeholder="Complemento"
                    id="complement"
                    name="Address.Complement"
                    type="text"
                    maxLength={40}
                  />
                </InputColumn>
                <InputColumn>
                  <div className="custom-selects address-selects">
                    <Select
                      size="large"
                      placeholder="Estado *"
                      onChange={(value) => handleChangeState(value)}
                      value={stateUser}
                      disabled={!isStateEnabled}
                    >
                      {statesBr.map((item: any) => {
                        return (
                          <option key={item.sigla} value={item.sigla}>
                            {item.sigla}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                  <div className="custom-selects address-selects">
      
                    <Select
                      size="large"
                      placeholder="Cidade *"
                      onChange={(value) => handleCityChange(value)}
                      value={city}
                      disabled={!isCityEnabled}
                    >
                      {citiesBr.map((item: any) => {
                        return (
                          <option key={item.nome} value={item.nome}>
                            {item.nome}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                </InputColumn>

                <h2>Dados Profissionais</h2>
                <InputColumn>
                  <Input
                    placeholder="Empresa *"
                    id="empresa"
                    name="Job.Company"
                    type="text"
                    maxLength={80}
                  />

                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptLocale}
                  >
                    <DatePicker
                      invalidDateMessage={'Data inválida'}
                      minDateMessage={
                        'A data não deve ser anterior à data mínima'
                      }
                      maxDateMessage={
                        'A data não deve ser posterior à data máxima'
                      }
                      variant="inline"
                      inputVariant="outlined"
                      margin="dense"
                      id="Job.AdmissionDate"
                      name="Job.AdmissionDate"
                      format="dd/MM/yyyy"
                      value={admissionDate}
                      placeholder="Data de admissão *"
                      onChange={handleChangeJobAdmissionDate}
                    />
                  </MuiPickersUtilsProvider>
                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="Telefone *"
                    id="jobPhone"
                    name="Job.Phone"
                    type="tel"
                    onChange={handleFormatJobPhone}
                    maxLength={15}
                    value={jobPhone}
                  />
                  <Input
                    placeholder="Cargo *"
                    id="cargo"
                    name="Job.Position"
                    type="text"
                    maxLength={40}
                  />
                </InputColumn>
                <InputColumn>
                  <Input
                    placeholder="Salário *"
                    id="salario"
                    name="Job.Salary"
                    prefix="R$"
                    onChange={handleChangeSalary}
                  />
                </InputColumn>
              </ContentInput>
              <div className="wrapper-btn">
                <Button
                  type="button"
                  className="btn-back"
                  onClick={handleBackPage}
                >
                  Voltar
                </Button>
                <Button type="submit">Concluir cadastro</Button>
              </div>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  )
}

export default RegisterCompany
