/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  useEffect
} from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Radio, Select } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToast } from '../../../hooks/toast'
import api from '../../../services/api'
import {
  Container,
  Content,
  AnimationContainer,
  Wrapper,
  DatePicker
} from './styles'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import getValidationErrors from '../../../utils/getValidationErrors'
import { cpfMask } from '../../../utils/cpfMask'
import { phoneMask } from '../../../utils/phoneMask'
import { setTabs } from '../../../store/modules/tabsRegisterUser/actions'
import { cepMask } from '../../../utils/cepMask'
import formatValue from '../../../utils/formatValue'

import DateFnsUtils from '@date-io/date-fns'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptLocale from 'date-fns/locale/pt-BR'
import Header from 'components/Header'

interface RegisterUserFormData {
  UserId: number
  BirthDate: string | null
  AlternativeEmail: string
  Rg: string
  Gender: string
  Phone: string
  CellPhone: string
  CivilStatus: string
  Name: string
  cpf: string
  Email: string
  CustomerId: number
  Address: {
    ZipCode: string
    Street: string
    Number: number
    District: string
    City: string
    State: string
    Complement: string
  }
  Job: {
    Company: string
    Office: string
    Salary: string
    FunctionalRelationship: string
    Phone: string
    AdmissionDate: null
  }
  Spouse: {
    Name: string
    Cpf: string
    SpouseMakesUpIncome: boolean
    Email: string
    Phone: string
    Salary: string
  } | null
}

const ContactPF: React.FC = () => {
  const { infoClient, orderId, userIdPf } = useParams<any>()

  // const userIdPf = Number(localStorage.getItem('@FleetSolutions:userIdPf'))

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()
  const { Option } = Select
  const dispatch = useDispatch()

  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [isStreetEnabled, setStreetEnabled] = useState(false)
  const [isCityEnabled, setCityEnabled] = useState(false)
  const [isStateEnabled, setStateEnabled] = useState(false)
  const [stateUser, setStateUser] = useState('')
  const [payloadStateUser, setPayloadStateUser] = useState('')
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [salary, setSalary] = useState('')
  const [birthDate, setBirthDate] = useState<string | null>(null)
  const [user, setUser] = useState({} as any)
  const [customer, setCustomers] = useState({} as any)
  const [admissionDate, setAdmissionDate] = useState(null)
  const [rg, setRg] = useState('')
  const [genderOptions, setGenderOptions] = useState([])
  const [selectedGender, setselectedGender] = useState('Selecione o gênero')
  const [civilStatus, setCivilStatus] = useState<any>(
    'Selecione o estado civil'
  )
  const [civilStatusOptions, setCivilStatusOptions] = useState([])
  const [jobPhone, setJobPhone] = useState('')
  const [customerId, setCustomerId] = useState(0)
  const [isSpouse, setIsSpouse] = useState(false)
  const [nameSpouse, setNameSpouse] = useState('')
  const [spouseEmail, setSpouseEmail] = useState('')
  const [spousePhone, setSpousePhone] = useState('')
  const [spouseSalary, setSpouseSalary] = useState('')
  const [cpfSpouse, setCpfSpouse] = useState('')

  const initialData = {
    Name: user.fullname,
    cpf: user.cpfCnpj,
    UserId: user.userId,
    AlternativeEmail: customer.alternativeEmail,
    BirthDate: birthDate,
    Rg: customer.rg,
    Gender: customer.gender,
    Phone: customer.phone,
    CellPhone: customer.cellPhone,
    CivilStatus: customer.civilStatus,
    Email: user.email,
    Address: {
      ZipCode: !isEmpty(customer.address) ? customer.address.zipCode : '',
      City: !isEmpty(customer.address) ? customer.address.city : '',
      Complement: !isEmpty(customer.address) ? customer.address.complement : '',
      District: !isEmpty(customer.address) ? customer.address.district : '',
      Number: !isEmpty(customer.address) ? customer.address.number : '',
      Street: !isEmpty(customer.address) ? customer.address.street : '',
      State: !isEmpty(customer.address) ? customer.address.state : ''
    },
    Job: {
      Name: !isEmpty(customer.job) ? customer.job.name : '',
      Salary: !isEmpty(customer.job) ? formatValue(customer.job.salary) : '',
      Company: !isEmpty(customer.job) ? customer.job.company : '',
      Position: !isEmpty(customer.job) ? customer.job.position : '',
      Phone: !isEmpty(customer.job) ? customer.job.phone : '',
      AdmissionDate: admissionDate
    },
    Spouse: {
      Name: !isEmpty(customer.spouse) ? customer.spouse.name : '',
      Cpf: !isEmpty(customer.spouse) ? customer.spouse.cpf : '',
      SpouseMakesUpIncome: !isEmpty(customer.spouse)
        ? customer.spouse.spouseMakesUpIncome
        : '',
      Email: !isEmpty(customer.spouse) ? customer.spouse.email : '',
      Phone: !isEmpty(customer.spouse) ? customer.spouse.phone : '',
      Salary: !isEmpty(customer.spouse)
        ? formatValue(customer.spouse.salary)
        : ''
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    api.get('/genderenum').then((response: any) => {
      setGenderOptions(response.data)
    })

    api.get('/civilstatusenum').then((response: any) => {
      setCivilStatusOptions(response.data)
    })

    api
      .get(`/users/${!orderId ? userIdPf : infoClient}`)
      .then((response: any) => {
        const { fullname, email, cpfCnpj } = response.data

        setCpf(cpfMask(cpfCnpj))

        if (fullname.includes('Client-') || email.includes('client_')) {
          response.data.fullname = ''
          response.data.email = ''
        }
        setUser(response.data)
      })

    api
      .get(`/customers/${!orderId ? userIdPf : infoClient}`)
      .then((response: any) => {
        if (!response.data.cellPhone) {
          response.data.gender = ''
          response.data.civilStatus = ''
        }
        setCustomers(response.data)
      })
  }, [infoClient])

  useEffect(() => {
    if (!isEmpty(customer)) {
      if (!isEmpty(customer.job)) {
        setAdmissionDate(customer.job.admissionDate)
        setSalary(customer.job.salary)
        setJobPhone(phoneMask(customer.job.phone))
      }
      if (!isEmpty(customer.address)) {
        handleUF(customer.address.state)

        setCep(cepMask(customer.address.zipCode))
        setStreet(customer.address.street)
        setDistrict(customer.address.district)
        customer.address.city
          ? setCity(customer.address.city)
          : setCityEnabled(true)
      }


      setBirthDate(customer.birthDate)
      setCivilStatus(customer.civilStatus)
      setCustomerId(customer.customerId)
      setselectedGender(customer.gender)
      setCellPhone(phoneMask(customer.cellPhone))
      setPhone(phoneMask(customer.phone))
      setRg(customer.rg)

      if (customer.spouse != null) {
        setNameSpouse(customer.spouse.name)
        setSpousePhone(phoneMask(customer.spouse.phone))
        setCpfSpouse(cpfMask(customer.spouse.cpf))
        setIsSpouse(customer.spouse.spouseMakesUpIncome)
        setSpouseSalary(customer.spouse.salary)
        setSpouseEmail(customer.spouse.email)
      }
    }
  }, [customer, user])

  const handleSubmit = useCallback(
    async (data: RegisterUserFormData) => {
      if (birthDate !== null) {
        const yearBirthdate = new Date(birthDate).getFullYear()
        const yearCurrent = new Date().getFullYear()

        if (yearCurrent - yearBirthdate < 18) {
          addToast({
            title: 'Erro ao cadastrar',
            type: 'error',
            description:
              'O aluguel de veículos é permitido apenas para maiores de idade.'
          })
          return
        }
      }

      if (selectedGender === 'Selecione o gênero') {
        addToast({
          title: 'Campo obrigatório',
          type: 'error',
          description: 'Selecione seu gênero'
        })
        return
      }

      if (civilStatus === 'Selecione o estado civil') {
        addToast({
          title: 'Campo obrigatório',
          type: 'error',
          description: 'Selecione seu estado civil'
        })
        return
      }

      if (customer.spouse) {
        data.Spouse = {
          Name: nameSpouse,
          Email: spouseEmail,
          SpouseMakesUpIncome: isSpouse,
          Phone: convertoToNumberOnly(spousePhone),
          Cpf: cpfSpouse.replace(/\D/g, ''),
          Salary: spouseSalary
        }
      }

      data.UserId = !orderId ? userIdPf : infoClient
      data.Gender = selectedGender
      data.CivilStatus = civilStatus
      data.Address.State = payloadStateUser
      data.BirthDate = birthDate
      data.Phone = convertoToNumberOnly(phone)
      data.CellPhone = convertoToNumberOnly(cellPhone)
      data.Job.Phone = convertoToNumberOnly(jobPhone)
      data.Job.AdmissionDate = admissionDate
      data.Job.Salary = salary
      data.Address.ZipCode = convertoToNumberOnly(cep)
      data.Rg = rg
      data.cpf = cpf

      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          Name: Yup.string().required('Nome obrigatório'),
          Rg: Yup.string().required('Campo obrigatório'),
          Phone: Yup.string()
            .required('Campo obrigatório')
            .min(10, 'Telefone inválido')
            .max(10, 'Telefone inválido'),
          CellPhone: Yup.string()
            .required('Campo obrigatório')
            .min(11, 'Celular inválido'),
          BirthDate: Yup.date().required(''),
          Email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          AlternativeEmail: Yup.string().email('Digite um e-mail válido'),
          Gender: Yup.number().required('Campo obrigatório'),
          CivilStatus: Yup.string().required('Campo obrigatório'),
          Address: Yup.object().shape({
            ZipCode: Yup.string()
              .required('Campo obrigatório')
              .min(8, 'CEP inválido')
              .max(8, 'CEP inválido'),
            Street: Yup.string().required('Campo obrigatório'),
            Number: Yup.string().required('Campo obrigatório'),
            District: Yup.string().required('Campo obrigatório'),
            City: Yup.string().required('Campo obrigatório'),
            State: Yup.string().required('Campo obrigatório')
          }),
          Job: Yup.object().shape({
            Company: Yup.string().required('Campo obrigatório'),
            AdmissionDate: Yup.date().required('Campo obrigatório'),
            Position: Yup.string().required('Campo obrigatório'),
            Salary: Yup.string().required('Campo obrigatório')
          })
        })
        await schema.validate(data, {
          abortEarly: false
        })

        data.cpf = data.cpf.replace(/\D/g, '')
        if (isEmpty(customer)) {
          await api
            .post('/customers', data)
            .then(() => {
              addToast({
                title: 'Cadastro realizado',
                type: 'success',
                description: 'Seu cadastro foi realizado com sucesso.'
              })
              history.goBack()
              dispatch(setTabs({ id: '3' }))
            })
            .catch((error) => {
              if (error.response.data.errors['Spouse.Cpf']) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description: 'Favor verificar o cpf do cônjuge'
                })
              }
              if (error.response.data.errors['Job.Phone']) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description: 'Favor verificar o telefone'
                })
              }
              if (error.response.data.errors.Cpf) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description:
                    'O CPF do cônjugue deve ser diferente do usuário cadastrado'
                })
              }
            })
        } else {
          data.CustomerId = customerId
          await api
            .put(`/customers?userId=${!orderId ? userIdPf : infoClient}`, data)
            .then(async () => {
              if (orderId) {
                await api.put('/orders/RetryCreditAnalysis', {
                  orderId: Number(orderId),
                  userId: Number(infoClient)
                })

                history.push(`/order-status-pf/${orderId}`)
              } else {
                addToast({
                  title: 'Cadastro editado',
                  type: 'success',
                  description: 'Seu cadastro foi editado com sucesso.'
                })
                history.goBack()
                dispatch(setTabs({ id: '3' }))
              }
            })
            .catch((error) => {
              if (error.response.data.errors['Spouse.Cpf']) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description: 'Favor verificar o cpf do cônjuge'
                })
              }
              if (error.response.data.errors['Job.Phone']) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description: 'Favor verificar o telefone'
                })
              }
              if (error.response.data.errors.Cpf) {
                addToast({
                  type: 'error',
                  title: 'Erro ao Cadastrar',
                  description:
                    'O CPF do cônjugue deve ser diferente do usuário cadastrado'
                })
              }
            })
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
          addToast({
            type: 'error',
            title: 'Erro ao Cadastrar',
            description: 'Preencha os campos corretamente.'
          })
        }
      }
    },
    [
      addToast,
      dispatch,
      history,
      cep,
      salary,
      jobPhone,
      rg,
      phone,
      cellPhone,
      civilStatus,
      selectedGender,
      spousePhone,
      cpfSpouse,
      spouseSalary,
      nameSpouse,
      spouseEmail,
      birthDate,
      admissionDate,
      stateUser,
      payloadStateUser
    ]
  )

  const handleKeyUpCep = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCep(cepMask(value))
    if (value.length === 9) {
      api
        .get(`/ceps?cep=${e.target.value}`)
        .then((response: any) => {
          if (!response.data.erro) {
            response.data.logradouro
              ? setStreet(response.data.logradouro)
              : setStreetEnabled(true)
            setDistrict(response.data.bairro)
            response.data.localidade
              ? setCity(response.data.localidade)
              : setCityEnabled(true)
            if(response.data.uf){
              handleUF(response.data.uf)
            }
            else{
              setStateEnabled(true)
            }
          }
        })
        .catch((error: any) => {
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'Endereço não encontrado'
          })
        })
    }
  }

  const handleFormatCpf = (item: any) => {
    setCpf(cpfMask(item.target.value))
  }

  const handleFormatPhone = (item: any) => {
    setPhone(phoneMask(item.target.value))
  }

  const handleFormatJobPhone = (item: any) => {
    setJobPhone(phoneMask(item.target.value))
  }

  const handleFormatCellPhone = (item: any) => {
    setCellPhone(phoneMask(item.target.value))
  }

  const handleUF = (currentUF: string | number) => {

    Promise.all([
        api.get(`location/state`),
        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
    ])
    .then(([responseLocalState, responseIBGE]) => {
        const localStateData = responseLocalState.data;
        const ibgeData = responseIBGE.data;
        if (typeof currentUF === 'number') {
            const estadoEncontrado = localStateData.find((estado:any) => estado.value === currentUF)?.text;
            const sigladoEstado = ibgeData.find((estado:any) => estado.nome.toUpperCase() === estadoEncontrado.toUpperCase())?.sigla;
            const idEstado = localStateData.find((estado:any) => estado.value === currentUF)?.value;
            setStateUser(sigladoEstado);
            setPayloadStateUser(idEstado);
        }
        else if (typeof currentUF === 'string') {
          const estadoEncontrado = ibgeData.find((estado:any) => estado.sigla === currentUF)?.nome;
          const idEstado = localStateData.find((estado:any) => estado.text.toUpperCase() === estadoEncontrado.toUpperCase())?.value;
          setStateUser(currentUF);
          setPayloadStateUser(idEstado);
        }
    })
    .catch(error => {
        console.error('Erro em uma das chamadas de API:', error);
    });

    // console.log(currentUF);
};


  const handleKeyUpRg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRg(convertoToNumberOnly(e.target.value))
  }, [])

  const formatCurrency = (value: any) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{2})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')

    return 'R$ ' + value
  }

  const convertoToNumberOnly = (value: any) => {
    return value.replace(/([^\d])+/gim, '')
  }

  const handleChangeSalary = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = formatCurrency(e.currentTarget.value)
    setSalary(convertoToNumberOnly(e.currentTarget.value).slice(0, -2))
  }, [])

  const handleSelectGender = (event: any) => {
    setselectedGender(event)
  }

  const handleSelectCivilStatus = (event: any) => {
    setCivilStatus(event)
  }

  const handleKeyUpNameSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNameSpouse(e.target.value)
    },
    []
  )

  const handleKeyUpEmailSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpouseEmail(e.target.value)
    },
    []
  )

  const handleKeyUpCpfSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCpfSpouse(cpfMask(e.target.value))
    },
    []
  )

  const handleChangeSpouseSalary = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget.value = formatCurrency(e.currentTarget.value)
      setSpouseSalary(convertoToNumberOnly(e.currentTarget.value).slice(0, -2))
    },
    []
  )

  const handleKeyUpPhoneSpouse = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSpousePhone(phoneMask(convertoToNumberOnly(e.target.value)))
    },
    []
  )

  const handleChangeJobAdmissionDate = (date: any) => {
    setAdmissionDate(date)
  }

  const handleChangeBirthDate = (date: any) => {
    setBirthDate(date)
  }

  const handleKeyUpStreet = (item: any) => {
    setStreet(item.target.value)
  }

  const handleKeyUpDistrict = (item: any) => {
    setDistrict(item.target.value)
  }

  const handleBackPage = () => {
    history.goBack()
  }

  return (
    <Container>
      <Header />
      <Content>
        <AnimationContainer>
          <h1>INSERIR DADOS DE CONTATO</h1>
          <p>Preencha os dados abaixo, sobre o responsável pelos veículos</p>
          <Form initialData={initialData} ref={formRef} onSubmit={handleSubmit}>
            <h2>Dados Pessoais</h2>
            <Input
              placeholder="CPF"
              id="cpf"
              name="cpf"
              type="text"
              maxLength={18}
              value={cpf}
              disabled={true}
              onChange={handleFormatCpf}
            />
            <Input
              placeholder="Nome Completo"
              id="Name"
              name="Name"
              maxLength={80}
              required
              type="text"
            />
            <Input
              placeholder="RG"
              value={rg}
              id="Rg"
              name="Rg"
              type="text"
              maxLength={13}
              onChange={handleKeyUpRg}
            />
            <Input
              placeholder="Telefone"
              id="Phone"
              maxLength={15}
              name="Phone"
              type="tel"
              value={phone}
              onChange={handleFormatPhone}
            />
            <Input
              placeholder="Celular"
              id="CellPhone"
              name="CellPhone"
              type="tel"
              maxLength={15}
              value={cellPhone}
              onChange={handleFormatCellPhone}
            />
            <Input
              placeholder="E-mail"
              id="Email"
              name="Email"
              maxLength={80}
              required
              type="text"
            />
            <Input
              placeholder="E-mail alternativo"
              id="AlternativeEmail"
              name="AlternativeEmail"
              type="email"
              maxLength={80}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <DatePicker
                invalidDateMessage={'Data inválida'}
                minDateMessage={'A data não deve ser anterior à data mínima'}
                maxDateMessage={'A data não deve ser posterior à data máxima'}
                variant="inline"
                inputVariant="outlined"
                className="birthday"
                placeholder="Data de nascimento"
                margin="dense"
                id="BirthDate"
                name="BirthDate"
                format="dd/MM/yyyy"
                value={birthDate}
                onChange={handleChangeBirthDate}
              />
            </MuiPickersUtilsProvider>

            <div className="custom-selects">
              <Select
                size="large"
                onChange={handleSelectGender}
                value={selectedGender}
              >
                {genderOptions.map((item: any) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div className="custom-selects">
              <Select
                size="large"
                onChange={handleSelectCivilStatus}
                value={civilStatus}
              >
                {civilStatusOptions.map((item: any) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  )
                })}
              </Select>
            </div>

            {(civilStatus === 2 || civilStatus === 5) && (
              <>
                <Wrapper>
                  <p>Cônjuge compõe renda?</p>
                  <Radio.Group
                    style={{ width: 'auto', marginLeft: 20 }}
                    value={isSpouse ? 1 : 2}
                  >
                    <Radio onClick={() => setIsSpouse(true)} value={1}>
                      Sim
                    </Radio>
                    <Radio onClick={() => setIsSpouse(false)} value={2}>
                      Não
                    </Radio>
                  </Radio.Group>
                </Wrapper>
              </>
            )}
            {isSpouse && (
              <>
                <Input
                  placeholder="Nome cônjuge"
                  id="nameSpouse"
                  name="Spouse.Name"
                  type="text"
                  maxLength={80}
                  value={nameSpouse}
                  onChange={handleKeyUpNameSpouse}
                />

                <Input
                  placeholder="CPF"
                  id="spouseCpf"
                  name="Spouse.Cpf"
                  type="text"
                  maxLength={18}
                  value={cpfSpouse}
                  onChange={handleKeyUpCpfSpouse}
                />
                <Input
                  id="spouseSalary"
                  name="Spouse.Salary"
                  prefix="R$"
                  placeholder="Salário cônjuge"
                  onChange={handleChangeSpouseSalary}
                />
                <Input
                  placeholder="Telefone"
                  id="phoneSpouse"
                  name="Spouse.Phone"
                  type="tel"
                  onChange={handleKeyUpPhoneSpouse}
                  maxLength={15}
                  value={spousePhone}
                />
                <Input
                  placeholder="Email cônjuge"
                  id="emailSpouse"
                  name="Spouse.Email"
                  type="email"
                  maxLength={80}
                  onChange={handleKeyUpEmailSpouse}
                />
              </>
            )}

            <h2>Endereço</h2>
            <Input
              placeholder="CEP"
              id="zipcode"
              name="Address.ZipCode"
              type="text"
              maxLength={9}
              onChange={handleKeyUpCep}
              value={cep}
            />
            <Input
              placeholder="Logradouro"
              id="street"
              name="Address.Street"
              type="text"
              maxLength={80}
              onChange={handleKeyUpStreet}
              value={street}
            />
            <Input
              placeholder="Número"
              id="text"
              name="Address.Number"
              type="number"
              maxLength={5}
            />
            <Input
              placeholder="Bairro"
              id="district"
              name="Address.District"
              type="text"
              value={district}
              onChange={handleKeyUpDistrict}
            />
            <Input
              placeholder="Complemento"
              id="complement"
              name="Address.Complement"
              type="text"
              maxLength={40}
            />
            <Input
              placeholder="Cidade"
              id="city"
              name="Address.City"
              type="text"
              maxLength={80}
              disabled={!isCityEnabled}
              value={city}
            />
            <Input
              placeholder="Estado"
              id="state"
              name="Address.State"
              type="text"
              maxLength={80}
              disabled={!isStateEnabled}
              value={stateUser}
            />

            <h2>Dados Profissionais</h2>
            <Input
              placeholder="Empresa"
              id="empresa"
              name="Job.Company"
              type="text"
              maxLength={80}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <DatePicker
                invalidDateMessage={'Data inválida'}
                minDateMessage={'A data não deve ser anterior à data mínima'}
                maxDateMessage={'A data não deve ser posterior à data máxima'}
                variant="inline"
                inputVariant="outlined"
                margin="dense"
                id="Job.AdmissionDate"
                name="Job.AdmissionDate"
                format="dd/MM/yyyy"
                value={admissionDate}
                placeholder="Data de admissão"
                onChange={handleChangeJobAdmissionDate}
              />
            </MuiPickersUtilsProvider>

            <Input
              placeholder="Telefone"
              id="jobPhone"
              name="Job.Phone"
              type="tel"
              onChange={handleFormatJobPhone}
              maxLength={15}
              value={jobPhone}
            />
            <Input
              placeholder="Cargo"
              id="cargo"
              name="Job.Position"
              type="text"
              maxLength={40}
            />
            <Input
              placeholder="Salário"
              id="salario"
              name="Job.Salary"
              prefix="R$"
              onChange={handleChangeSalary}
            />

            <div className="wrapper-btn">
              <Button
                type="button"
                className="btn-back"
                onClick={handleBackPage}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default ContactPF
