/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useRef } from 'react'

import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory, Link } from 'react-router-dom'
import { Alert, Modal } from 'antd'
import { useToast } from '../../hooks/toast'

import { SEGMENTS, converterData, findPersonType } from 'utils'
import * as S from './styles'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import api from '../../services/api'
import { useAuth } from '../../hooks/auth'
import {
  Container,
  Select,
  WrapperTable,
  Table,
  ContentModal,
  ContentModalContrato,
  WrapperLogo,
  SelectSegmentButtons
} from './styles'
import Button from '../../components/Button'
import Input from '../../components/Input'
import isonSearch from '../../assets/icons/search-icon.svg'
import { formatDate } from '../../utils/formatDate'
import formatValue from '../../utils/formatValue'
import { cpfMask } from '../../utils/cpfMask'
import { useSegments } from '../../hooks/useSegments'
import Loading from '../../components/Loading'
import PaginationList from '../../components/PaginationList'
import { AutoComplete } from 'antd'
import OrderModal from './OrderModal'
import { cnpjMask } from 'utils/cnpjMask'
import { onlyNumbersRegex } from 'utils/regex'
import { DatePicker } from 'antd'
import iconCalendar from '../../assets/icons/calendar.svg'
import moment from 'moment'
import { ISegment } from 'utils/types'
import { filterOffers } from 'store/modules/offers/actions'

const { Option } = AutoComplete
const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

interface IStatusOrder {
  ordersCreating: number
  ordersAwaitingContractSigning: number
  ordersCreditAnalysis: number
  vehicleInProccess: number
  vehiclesDelivered: number
  ordersCanceled: number
}

interface IStatusOrderV2 {
  pendingLM: number
  pendingVendedor: number
  pendingCliente: number
  orderCreated: number
  authencity: number
  antifraud: number
  credit: number
  contract: number
}

interface IStatusOrderItemQuantity{
  vehicleCancelled:      number;
  awaitingBilling:       number;
  awaitingPreparation:   number;
  awaitingDocumentation: number;
  awaitingTransport:     number;
  vehicleDelivered:      number;
  vehicleAvailable:      number;
}

const PER_PAGE = 20
const INITIAL_PAGE = 1

const Orders: FC = () => {
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()
  const { dealershipId, dealershipGroupId, role, userId, roleId } = useAuth()

  const [statusOrders, setStatusOrders] = useState<IStatusOrder>()
  const [loadingOrderStatus, setLoadingOrderStatus] = useState(true)
  const [orders, setOrders] = useState([])
  const [newOrder, setNewOrder] = useState(false)
  const [clientType, setClientType] = useState('')
  const [viewType, setViewType] = useState('2')

  const [cnpjSearch, setCnpjSearch] = useState('')
  const [doc, setDoc] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [orderIdSearch, setOrderNumberSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('-1')
  const [segment, setSegment] = useState(null)



  
  const [isLoading, setIsLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [orderId, setOrderId] = useState(0)

  const [statusOrdersV2, setStatusOrdersV2] = useState<IStatusOrderV2>()
  const [statusOrdersDelivery, setStatusOrdersDelivery] = useState<IStatusOrderItemQuantity>()

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [total, setTotal] = useState(0)
  const [isFilterOrder, setIsFilterOrder] = useState(false)

  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const [value, setValue] = useState('')

  const [userDnId, setUserDnId] = useState(0)
  const [textCancel, setTextCancel] = useState('')

  const [dataStart, setDataStart] =useState<string | null>(moment().subtract(1, 'years').format('YYYY-MM-DD'));
  const [dataEnd, setDataEnd] = useState<string | null>(moment().format('YYYY-MM-DD'));

  const [clientName, setClientName] = useState('')
  const [clientNameSearch, setClientNameSearch] = useState('')

  const cleanCpfCnpj = cnpjSearch.replace(onlyNumbersRegex, '')

  const [segmentOptions, setSegmentOptions] = useState<ISegment[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const [contractModalVisible, setContractModalVisible] = useState<boolean>(false);
  const [currentContract, setCurrentContract] = useState<any>(null);
  const [contractOwner, setContractOwner] = useState<any>(null);
  
  

  useEffect(() => {
    if(!segmentOptions.length){
      loadSegments()
    }
    // if (!dataStart) setDataStart('2000-01-01')

    // if (!dataEnd) setDataEnd('2098-01-01')
    let newView = localStorage.getItem("@FleetSolutions:newView");
    if(newView === 'true'){
      history.push('/orders')
    }


    if (!userId && history !== undefined) {
      history.push('/')
    }



  }, [currentPage, isFilterOrder, userDnId])

  useEffect(() => {
    if(selectedStatus !== '-1' || cleanCpfCnpj || orderIdSearch || clientName || segment){
      setDataStart(null)
      setDataEnd(null)
    }
    // handleFilterOrder('')

  }, [selectedStatus, cleanCpfCnpj, orderIdSearch])

  useEffect(() => {
    if(selectedStatus !== '-1' || cleanCpfCnpj || orderIdSearch || clientName || segment){
      setDataStart(null)
      setDataEnd(null)
    }
    // if(segment){
    //   handleFilterOrder('')
    // }
  }, [segment])

  useEffect(() => {

    setStatusOptions([
      { value: "-1", label: "Todos" },
      { value: ",1,", label: "Criado" },
      { value: ",12,13,14,", label: "Autenticidade" },
      { value: ",2,3,4,", label: "Antifraude" },
      { value: ",19,20,21,", label: "Cartão de Crédito" },
      { value: ",18,5,6,7,8,", label: "Crédito" },
      { value: ",9,10,15,16,", label: "Contrato" },
      { value: ",11,", label: "Concluído" },
      { value: ",17,", label: "Logística" },
      { value: ",-99,", label: "Autoatendimento" },
      { value: ",-98,", label: "Concessionária", show: roleId.toString() === '265' || roleId.toString() === '298' },
      { value: ",0,", label: "Cancelado" },
      { value: ",-1,", label: "Expirado" }
    ])
  }, [roleId])

  useEffect(() => {
      getOrders()

  }, [])

  useEffect(() => {
    if(filtersChanged){
      getOrders()
      setFiltersChanged(false)
    }
  }, [filtersChanged])

  const getOrders = async () => {
    setIsLoading(true)
    await api
    .get(`/dealership-management`, {
      params: {
        dealershipId,
        dealershipGroupId,
        role,
        cpfCnpj: cleanCpfCnpj,
        orderId: orderIdSearch,
        client: clientType || '',
        status: selectedStatus === '-1' ? null : selectedStatus,
        userDnId,
        QuantityPerPage: PER_PAGE,
        Page: true,
        CurrentPage: currentPage,
        dateStart: dataStart,
        dateEnd: dataEnd,
        clientName: clientNameSearch,
        segment: segment
      }
    })
    .then((response) => {
      const { data } = response

      // setCurrentPage(INITIAL_PAGE)
      setTotal(data?.items?.totalAmount)
      setIsLoading(false)
      setOrders(data?.items?.itens)
      setIsLoading(false)
    })

  api
    .get(`/dealership-management/totals/v2`, {
      params: {
        dealershipId,
        dealershipGroupId,
        role,
        cpfCnpj: cleanCpfCnpj,
        orderId: orderIdSearch,
        client: clientType,
        userDnId,
        status: selectedStatus === '-1' ? null : selectedStatus,
        QuantityPerPage: PER_PAGE,
        Page: true,
        CurrentPage: currentPage,
        dateStart: dataStart,
        dateEnd: dataEnd,
        clientName: clientNameSearch,
        segment: segment
      }
    })
    .then((response) => {
      const { data } = response
      setStatusOrdersV2(data?.orderQuantityV2)
      setStatusOrdersDelivery(data?.orderItemQuantity)
      setIsLoading(false)
    })
  }

  const loadSegments = () => {

    


    api.get(`/dealerships/${dealershipId}`).then((res) => {
      const { data } = res
      api
      .get(SEGMENTS)
      .then(({ data }) => {

        const filtered = data.filter((item1: { iD_Fleet: any; iD_Ibratan: any }) => {
            return res.data.listSegments.some((item2: { idSegment: any; idIbratan: any }) => {
                return item1.iD_Fleet === item2.idSegment && item1.iD_Ibratan === item2.idIbratan;
            });
        });

        setSegmentOptions(filtered)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: `Não foi possível listar os segmentos`,
          description: 'Por favor, entre em contato com o suporte.'
        })
      })
    })



  }

  const handleNewOrder = () => {
    localStorage.removeItem("@FleetSolutions:orderItems");
    setNewOrder(true)
  }

  const showOrderStatus = (item: any) => {
    localStorage.setItem('@FleetSolutions:userIdCompany', item.userId)

    if (item.clientType === 0) {
      history.push(`/order-status/${item.orderId}`)
    } else {
      history.push(`/order-status-pf/${item.orderId}`)
    }
  }

  const showOldSendContract = (item: any) => {
    if (item.clientType === 0) {
      history.push(
        `/contacts-company/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
      )
    } else {
      history.push(
        `/contacts-customer/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
      )
    }
  }

  const showContractDialog = (item : any) =>{
    setCurrentContract(item)
    api
    .get(`/orders/check-contract-userassigned/${item.orderId}/${Number(localStorage.getItem('@FleetSolutions:userId'))}`)
    .then((response) => {
      setContractOwner(response.data)
      setContractModalVisible(true)
    })
  }

  const modalClose = () => {
    setCurrentContract(null)
    setContractModalVisible(false)
  }

  const modalOk = () => {
    showSendContract(currentContract)
  }


  const showSendContract = (item: any) => {
    const data = {
      orderId: item.orderId,
      userIdContractAssigned: Number(localStorage.getItem('@FleetSolutions:userId'))
    }
    api
    .put(`/orders/update-contract-userassigned?orderId=${item.orderId}&userIdContractAssigned=${Number(localStorage.getItem('@FleetSolutions:userId'))}`)
    .then((response) => {
      setCurrentContract(null)
      setContractModalVisible(false)
      if (item.clientType === 0) {
        history.push(
          `/contacts-company/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
        )
      } else {
        history.push(
          `/contacts-customer/${item.cpfCnpj}/${item.orderId}/${item.clientType}/${item.userId}`
        )
      }
    })


  }

  const handleClearFilters = () => {
    setCurrentPage(1)
    setNewOrder(false)
    setCnpjSearch('')
    setOrderNumberSearch('')
    setClientType('')
    setViewType('2')
    setOrderNumber('')
    setDoc('')
    setSelectedStatus('-1')
    setIsFilterOrder(false)
    setDataStart(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    setDataEnd(moment().format('YYYY-MM-DD'))
    setClientName('')
    setClientNameSearch('')
    setSegment(null)


    setOptions([])
    onChange(undefined)
    setValue('')

    setFiltersChanged(true)
  }

  const handleStatusChange = async (status: string) => {
    setCurrentPage(1)
    
    setSelectedStatus('')
    setSelectedStatus(status)
    setIsFilterOrder(true)
    setDataStart(null)
    setDataEnd(null)
    setFiltersChanged(true)
  }

  const handleChangeView = async (ct: string) => {
    setViewType(ct)
  }

  const handleDatesValue = (data:any) =>{
    return data ? moment(data) : null
  }

  const handleFilterDate = async (start: string, end: string) => {
    setDataStart(start !== "" ? start : null)
    setDataEnd(end !== "" ? end : null)

    setIsLoading(true)

    let requestParams:any = {
      dealershipId,
      dealershipGroupId,
      role,
      cpfCnpj: cleanCpfCnpj,
      orderId: orderIdSearch,
      client: clientType,
      status: selectedStatus === '-1' ? null : selectedStatus,
      userDnId,
      QuantityPerPage: PER_PAGE,
      Page: true,
      CurrentPage: INITIAL_PAGE,
      dateStart: start !== "" ? start : null,
      dateEnd: end !== "" ? end : null,
      clientName: clientNameSearch,
      segment: segment
    }

    if(requestParams.dateStart === null){ 
      delete requestParams.dateStart
      delete requestParams.dateEnd
    }

    await api
      .get(`/dealership-management`, {
        params: requestParams
      })
      .then((response) => {
        const { data } = response

        setCurrentPage(INITIAL_PAGE)
        setTotal(data?.items?.totalAmount)
        setIsLoading(false)
        setOrders(data?.items?.itens)
      })

    // GET Status
    api
      .get(`/dealership-management/totals/v2`, {
        params: requestParams
      })
      .then((response) => {
        const { data } = response
        setStatusOrdersV2(data?.orderQuantityV2)
        setStatusOrdersDelivery(data?.orderItemQuantity)
      })
  }

  const handleFilterOrder = async (ct: string) => {

    setIsLoading(true)

    setClientType(ct)
    setIsFilterOrder(true)


    await api
      .get(`/dealership-management`, {
        params: {
          dealershipId,
          dealershipGroupId,
          role,
          cpfCnpj: cleanCpfCnpj,
          orderId: orderIdSearch,
          client: ct || '',
          status: selectedStatus === '-1' ? null : selectedStatus,
          userDnId,
          QuantityPerPage: PER_PAGE,
          Page: true,
          CurrentPage: INITIAL_PAGE,
          dateStart: dataStart,
          dateEnd: dataEnd,
          clientName: clientNameSearch,
          segment: segment
        }
      })
      .then((response) => {
        const { data } = response

        setCurrentPage(INITIAL_PAGE)
        setTotal(data?.items?.totalAmount)
        setIsLoading(false)
        setOrders(data?.items?.itens)
      })

    api
      .get(`/dealership-management/totals/v2`, {
        params: {
          dealershipId,
          dealershipGroupId,
          role,
          cpfCnpj: cleanCpfCnpj,
          orderId: orderIdSearch,
          client: clientType,
          userDnId,
          status: selectedStatus === '-1' ? null : selectedStatus,
          QuantityPerPage: PER_PAGE,
          Page: true,
          CurrentPage: currentPage,
          dateStart: dataStart,
          dateEnd: dataEnd,
          clientName: clientNameSearch,
          segment: segment
        }
      })
      .then((response) => {
        const { data } = response
        setStatusOrdersV2(data?.orderQuantityV2)
        setStatusOrdersDelivery(data?.orderItemQuantity)
      })
  }

  const handleSubmitSearch = () => {
    console.log('OK')
  }

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    setFiltersChanged(true)
  }

  const onSearch = (searchText: string) => {
    getUsers(searchText)
  }

  const onSelect = (val: string, option: any) => {
    setIsFilterOrder(true)
    setUserDnId(option.key)
  }

  const onChange = (data: any) => {
    setValue(data)
    if (data === undefined) setUserDnId(0)
  }

  const handleChangeTextCancel = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setTextCancel(event.target.value)
  }

  const handleSubmitCancel = async () => {
    if (textCancel) {

      await api
      .post(`/orderstatus`,  {
          orderId,
          justifyCancellation: textCancel
        })
      .then((response) => {
        setVisible(false)
        history.go(0)
      })
      .catch((error) => {
        addToast({
          title: 'Atenção!',
          type: 'error',
          description:
            'Erro ao cancelar pedido'
        })
      })
    } else {
      addToast({
        title: 'Atenção!',
        type: 'error',
        description:
          'Precisa ser informado um texto para justificar o cancelamento.'
      })
    }
  }

  const handleRemove = (item: any) => {
    setOrderId(item.orderId)
    setVisible(true)
  }

  const getUsers = async (value: string) => {
    try {
      await api
        .get(`/users/dealership-all-users-filter?name=${value}`)
        .then((response) => {
          const user: any = []
          response?.data?.itens?.forEach((element: { userId: any; fullname: any }) => {
            const item = { value: element.userId, label: element.fullname }
            user.push(item)
          })
          setOptions(user)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const StatusTable = ({bgColor, status, title, statusExplained}:any) => {
    const totalStatus =
      (status?.awaitingBilling ?? 0) +
      (status?.awaitingDocumentation ?? 0) +
      (status?.awaitingPreparation ?? 0) +
      (status?.awaitingTransport ?? 0) +
      (status?.vehicleDelivered ?? 0) +
      (status?.vehicleAvailable ?? 0);
  
    const calculatePercentage = (value:any) =>
      isNaN(value / totalStatus * 100)
        ? '0%'
        : `${((value / totalStatus) * 100).toFixed(2)}%`;
  
    return (
      <td style={{ flex: '1' }}>
        <table
          style={{
            width: '100%',
            border: '1px solid #C5C5C5',
            backgroundColor: '#F8F8F8',
            borderCollapse: 'initial',
            height: '100%'
          }}
        >
          <tr>
            <td
              rowSpan={3}
              style={{
                verticalAlign: 'top',
                paddingTop: '27px',
                paddingLeft: '20px'
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: bgColor,
                  borderRadius: '50%',
                  paddingLeft: '10px;'
                }}
              />
            </td>
            <td
              style={{
                width: '90%',
                paddingLeft: '10px',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#000',
                paddingTop: '23px',
                paddingBottom: '20px'
              }}
            >
              {title}
              <br />
              <span style={{ color: '#7E7E91', fontSize: '12px', fontWeight: '400' }}>
                {calculatePercentage(statusExplained)}
              </span>
            </td>
            <td>|</td>
            <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusExplained}
              </td>
            </tr>
          </table>
        </td>
      );
    };
  
  const handleViewTypeStageDelivery = () => {
    return (
      <tr style={{ display: 'flex', gap: '5px' }}>
        <StatusTable bgColor='red' status={statusOrdersDelivery} title='Aguardando Atribuição de Veículo' statusExplained={statusOrdersDelivery?.awaitingBilling} />
        <StatusTable bgColor='#FF772B' status={statusOrdersDelivery} title='Veículo em Preparação - Documentação' statusExplained={statusOrdersDelivery?.awaitingDocumentation} />
        <StatusTable bgColor='purple' status={statusOrdersDelivery} title='Veículo em Preparação de Acessórios' statusExplained={statusOrdersDelivery?.awaitingPreparation} />
        <StatusTable bgColor='#0000FF' status={statusOrdersDelivery} title='Veículo em Transporte' statusExplained={statusOrdersDelivery?.awaitingTransport} />
        <StatusTable bgColor='yellow' status={statusOrdersDelivery} title='Aguardando Disponibilidade de Retirada' statusExplained={statusOrdersDelivery?.vehicleAvailable} />
        <StatusTable bgColor='#1BD964' status={statusOrdersDelivery} title='Veículo Entregue' statusExplained={statusOrdersDelivery?.vehicleDelivered} />
      </tr>
    );
  }
  

  const handleViewTypeStageSales = () => {
    return (
      <tr>
        <td style={{ width: '19%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#F84339',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Criação do Pedido<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.orderCreated ?? 0) /
                      ((statusOrdersV2?.orderCreated ?? 0) +
                        (statusOrdersV2?.authencity ?? 0) +
                        (statusOrdersV2?.antifraud ?? 0) +
                        (statusOrdersV2?.credit ?? 0) +
                        (statusOrdersV2?.contract ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.orderCreated ?? 0) /
                          ((statusOrdersV2?.orderCreated ?? 0) +
                            (statusOrdersV2?.authencity ?? 0) +
                            (statusOrdersV2?.antifraud ?? 0) +
                            (statusOrdersV2?.credit ?? 0) +
                            (statusOrdersV2?.contract ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.orderCreated}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '19%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#FF772B',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#333333',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Autenticidade<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.authencity ?? 0) /
                      ((statusOrdersV2?.orderCreated ?? 0) +
                        (statusOrdersV2?.authencity ?? 0) +
                        (statusOrdersV2?.antifraud ?? 0) +
                        (statusOrdersV2?.credit ?? 0) +
                        (statusOrdersV2?.contract ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.authencity ?? 0) /
                          ((statusOrdersV2?.orderCreated ?? 0) +
                            (statusOrdersV2?.authencity ?? 0) +
                            (statusOrdersV2?.antifraud ?? 0) +
                            (statusOrdersV2?.credit ?? 0) +
                            (statusOrdersV2?.contract ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.authencity}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '19%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'purple',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#8649BA',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Antifraude<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.antifraud ?? 0) /
                      ((statusOrdersV2?.orderCreated ?? 0) +
                        (statusOrdersV2?.authencity ?? 0) +
                        (statusOrdersV2?.antifraud ?? 0) +
                        (statusOrdersV2?.credit ?? 0) +
                        (statusOrdersV2?.contract ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.antifraud ?? 0) /
                          ((statusOrdersV2?.orderCreated ?? 0) +
                            (statusOrdersV2?.authencity ?? 0) +
                            (statusOrdersV2?.antifraud ?? 0) +
                            (statusOrdersV2?.credit ?? 0) +
                            (statusOrdersV2?.contract ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.antifraud}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '19%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#0000FF',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                ></div>
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#8649BA',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Crédito<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.credit ?? 0) /
                      ((statusOrdersV2?.orderCreated ?? 0) +
                        (statusOrdersV2?.authencity ?? 0) +
                        (statusOrdersV2?.antifraud ?? 0) +
                        (statusOrdersV2?.credit ?? 0) +
                        (statusOrdersV2?.contract ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.credit ?? 0) /
                          ((statusOrdersV2?.orderCreated ?? 0) +
                            (statusOrdersV2?.authencity ?? 0) +
                            (statusOrdersV2?.antifraud ?? 0) +
                            (statusOrdersV2?.credit ?? 0) +
                            (statusOrdersV2?.contract ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.credit}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '19%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#1BD964',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                ></div>
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#8649BA',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Contrato<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400',
                    border: '1px solid #9898A7'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.contract ?? 0) /
                      ((statusOrdersV2?.orderCreated ?? 0) +
                        (statusOrdersV2?.authencity ?? 0) +
                        (statusOrdersV2?.antifraud ?? 0) +
                        (statusOrdersV2?.credit ?? 0) +
                        (statusOrdersV2?.contract ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.contract ?? 0) /
                          ((statusOrdersV2?.orderCreated ?? 0) +
                            (statusOrdersV2?.authencity ?? 0) +
                            (statusOrdersV2?.antifraud ?? 0) +
                            (statusOrdersV2?.credit ?? 0) +
                            (statusOrdersV2?.contract ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.contract}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    )
  }

  const handlerViewTypePending = () => {
    return (
      <tr>
        <td style={{ width: '30%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#F84339',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                LM Mobilidade<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.pendingLM ?? 0) /
                      ((statusOrdersV2?.pendingLM ?? 0) +
                        (statusOrdersV2?.pendingVendedor ?? 0) +
                        (statusOrdersV2?.pendingCliente ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.pendingLM ?? 0) /
                          ((statusOrdersV2?.pendingLM ?? 0) +
                            (statusOrdersV2?.pendingVendedor ?? 0) +
                            (statusOrdersV2?.pendingCliente ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.pendingLM}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '30%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#FF772B',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#333333',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Vendedor<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.pendingVendedor ?? 0) /
                      ((statusOrdersV2?.pendingLM ?? 0) +
                        (statusOrdersV2?.pendingVendedor ?? 0) +
                        (statusOrdersV2?.pendingCliente ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.pendingVendedor ?? 0) /
                          ((statusOrdersV2?.pendingLM ?? 0) +
                            (statusOrdersV2?.pendingVendedor ?? 0) +
                            (statusOrdersV2?.pendingCliente ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.pendingVendedor}
              </td>
            </tr>
          </table>
        </td>
        <td style={{ width: '30%' }}>
          <table
            style={{
              width: '98%',
              border: '1px solid #C5C5C5',
              backgroundColor: '#F8F8F8',
              borderCollapse: 'initial'
            }}
          >
            <tr>
              <td
                rowSpan={3}
                style={{
                  verticalAlign: 'top',
                  paddingTop: '27px',
                  paddingLeft: '20px'
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'purple',
                    borderRadius: '50%',
                    paddingLeft: '10px;'
                  }}
                />
              </td>
              <td
                style={{
                  width: '90%',
                  paddingLeft: '10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#8649BA',
                  paddingTop: '23px',
                  paddingBottom: '20px'
                }}
              >
                Cliente<br></br>
                <span
                  style={{
                    color: '#7E7E91',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}
                >
                  {isNaN(
                    ((statusOrdersV2?.pendingCliente ?? 0) /
                      ((statusOrdersV2?.pendingLM ?? 0) +
                        (statusOrdersV2?.pendingVendedor ?? 0) +
                        (statusOrdersV2?.pendingCliente ?? 0))) *
                      100
                  )
                    ? '0%'
                    : (
                        ((statusOrdersV2?.pendingCliente ?? 0) /
                          ((statusOrdersV2?.pendingLM ?? 0) +
                            (statusOrdersV2?.pendingVendedor ?? 0) +
                            (statusOrdersV2?.pendingCliente ?? 0))) *
                        100
                      ).toFixed(2) + '%'}
                </span>
              </td>
              <td>|</td>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '20px',
                  paddingLeft: '10px',
                  fontSize: '20px',
                  color: '#17536C'
                }}
              >
                {statusOrdersV2?.pendingCliente}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    )
  }

  const viewTypeHandlers: { [key: string]: () => JSX.Element } = {
    '1': handlerViewTypePending,
    '2': handleViewTypeStageSales,
    '3': handleViewTypeStageDelivery
  }

  const viewTypeHandler = viewTypeHandlers[viewType] || (() => <></>)

  const handleSegmentChange = (segment:any) => {
    setCurrentPage(1)
    setSegment(segment)
    setDataStart(null)
    setDataEnd(null)
    setFiltersChanged(true)

  }

  return (
    <>
      <Header />
      <Container>
        <div style={{ width: '90%', textAlign: 'left', paddingTop: '0px;' }}>
          <div style={{ marginTop: '16px', cursor: 'pointer' }}>
            <Alert  message="Uma nova versão da tela de pedidos está disponível. Clique para conhecer." onClick={() => history.push('orders')} type="info" showIcon />
          </div>
          <table style={{ width: '100%' }}>
            <tr>
              <td style={{ width: '198px' }}>
                <h1
                  data-testid="title"
                  style={{ textAlign: 'left', paddingRight: '15px' }}
                >
                  Meus Pedidos
                </h1>
              </td>
              <td
                style={{
                  color: '#CCCCCC',
                  paddingTop: '29px',
                  fontSize: '23px',
                  fontWeight: 0
                }}
              >
                |
              </td>
              <td
                style={{
                  paddingTop: '30px',
                  paddingLeft: '20px',
                  width: '370px'
                }}
              >
                <S.PanelInputSelected>
                  <img
                    src={iconCalendar}
                    style={{
                      paddingLeft: '20px'
                    }}
                    alt="Calendario"
                  />
                  <RangePicker
                    value={[handleDatesValue(dataStart), handleDatesValue(dataEnd)]}
                    style={{ border: 0 }}
                    format={dateFormat}
                    onChange={(_, dateString) => {
                      
                      if (dateString) {
                        handleFilterDate(
                          dateString[0] ? converterData(dateString[0]) : dateString[0],
                          dateString[1] ? converterData(dateString[1]) : dateString[1],
                        )
                      }
                    }}
                  />
                </S.PanelInputSelected>
              </td>
              <td
                style={{
                  color: '#CCCCCC',
                  paddingTop: '29px',
                  fontSize: '23px'
                }}
              >
                |
              </td>
              <td
                style={{ color: '#CCCCCC', paddingTop: '29px', width: '20px' }}
              >
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    color: '#17536C',
                    paddingLeft: '10px'
                  }}
                >
                  {total}
                </span>
              </td>
              <td
                style={{
                  fontSize: '12px',
                  color: '#666666',
                  paddingLeft: '10px',
                  paddingTop: '29px'
                }}
              >
                pedidos no período selecionado
              </td>
              <td
                style={{ width: '40%', textAlign: 'right', paddingTop: '30px' }}
              >
                {role !== 'Atendimento' && (
                  <Button onClick={handleNewOrder}>NOVO PEDIDO</Button>
                )}
              </td>
            </tr>
          </table>
        </div>
        <br></br>
        {!isLoading ? (
          <table style={{ width: '90%' }}>
            <tr>
              <td colSpan={99}>
                <select
                  style={{
                    border: 'none',
                    marginBottom: '10px',
                    fontSize: '16px',
                    backgroundColor: 'rgb(241, 241, 241)'
                  }}
                  onChange={(e) => handleChangeView(e.target.value)}
                  value={viewType}
                  name="viewType"
                >
                  <option value="3">Por Etapa no Processo de Entrega</option>
                  <option value="2">Por Etapa no Processo de Vendas</option>
                  <option value="1">Por Pendência</option>
                </select>
              </td>
            </tr>
            {viewTypeHandler()}
          </table>
        ) : (
          <Loading></Loading>
        )}

        <br></br>

        <WrapperTable
          data-testid="el_wrapper_table"
          style={{ paddingTop: '20px' }}
        >
          <Form onSubmit={handleSubmitSearch} ref={formRef}>
            <div className="div-filtros">
              <label htmlFor="status-orders" style={{ fontSize: '14px' }}>
                Status 
              </label>
              <Select
                data-testid="select_status"
                name="selectedStatus"
                value={selectedStatus}
                id="selectedStatus"
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                {statusOptions.map((option) => (
                  (option.show === undefined || option.show) && <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </Select>
            </div>
            <div className="div-filtros">
              <div>
                <label style={{ fontSize: '14px' }}>Documento</label>
              </div>
              <div className="wrapper-input">
                <Input
                  placeholder="Digite o documento"
                  id="doc"
                  data-testid="document"
                  name="doc"
                  type="text"
                  value={doc}
                  onChange={(e) => {
                    setCnpjSearch(e.target.value)
                    setDoc(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleFilterOrder('')
                    }
                  }}
                />
                <img
                  onClick={() => handleFilterOrder('')}
                  src={isonSearch}
                  alt="Search button"
                />
              </div>
            </div>
            <div className="div-filtros">
              <label htmlFor="clientType" style={{ fontSize: '14px' }}>
                Pessoa
              </label>
              <Select
                onChange={(e) => handleFilterOrder(e.target.value)}
                value={clientType}
                name="clientType"
                data-testid="client_type"
              >
                <option value=""> Todos</option>
                <option value="1">Física</option>
                <option value="0">Jurídica</option>
              </Select>
            </div>
            <div className="div-filtros">
              <div style={{ fontSize: '14px' }}>Pedido</div>
              <div className="wrapper-input">
                <Input
                  data-testid="order_number"
                  placeholder="Número do pedido"
                  id="number-order"
                  name="number-order"
                  type="text"
                  style={{ width: '150px' }}
                  value={orderNumber}
                  onChange={(e) => {
                    setOrderNumberSearch(e.target.value)
                    setOrderNumber(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleFilterOrder('')
                    }
                  }}
                />

                <img
                  onClick={() => handleFilterOrder('')}
                  src={isonSearch}
                  alt="Search button"
                />
              </div>
            </div>
            <div className="div-filtros">
              <div style={{ fontSize: '14px' }}>Cliente</div>
              <div className="wrapper-input">
                <Input
                  data-testid="clientName"
                  placeholder="Nome do cliente"
                  id="client-name"
                  name="client-name"
                  type="text"
                  style={{ width: '150px' }}
                  value={clientName}
                  onChange={(e) => {
                    setClientName(e.target.value)
                    setClientNameSearch(e.target.value)
                    if(e.target.value){
                      setDataStart(null)
                      setDataEnd(null)
                    }
                    else{
                      setDataStart(moment().subtract(1, 'years').format('YYYY-MM-DD'))
                      setDataEnd(moment().format('YYYY-MM-DD'))
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleFilterOrder('')
                    }
                  }}
                />

                <img
                  onClick={() => handleFilterOrder('')}
                  src={isonSearch}
                  alt="Search button"
                />
              </div>
            </div>

            <div className="div-filtros">
            <div style={{ fontSize: '14px' }}>Segmento</div>
            <div className="wrapper-input">
            <Select
                data-testid="segment"
                name="segment"
                value={segment || `-1`}
                id="segment"
                onChange={(e) => {
                  handleSegmentChange(e.target.value)
                }}
            >
                <option key={0} value={"0"}>
                    Todos
                </option>

                {segmentOptions.map(({ id, iD_Ibratan, type, name }) => (
                    <option key={id?.toString()} value={iD_Ibratan}>
                        {findPersonType(type)?.label} - {name}
                    </option>
                ))}
            </Select>
            </div>

            </div>

            <div
              style={{
                textAlign: 'right',
                height: '5px',
                paddingTop: '0px',
                fontSize: '14px'
              }}
            >
              {
                // eslint-disable-next-line
                <a href="#" onClick={() => handleClearFilters()}>
                  Limpar filtros
                </a>
              }
            </div>
          </Form>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Segmento</th>
                  <th>Tipo</th>
                  <th>Nome/Razão Social</th>
                  <th>Documento</th>
                  <th>Data de Inclusão</th>
                  <th>Data de Status</th>
                  <th>Valor Total</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {orders.length && !isLoading ? (
                  orders.map((item: any, i: number) => {
                    return (
                      <tr key={i} style={{ cursor: 'pointer' }}>
                        <td onClick={() => showOrderStatus(item)}>
                          {item?.prefix}
                          {item.orderId}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {item?.segmentName}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {item.clientType === 0 ? 'Jurídica' : 'Física'}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {item.name}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {item.clientType === 0
                            ? cnpjMask(item.cpfCnpj)
                            : cpfMask(item.cpfCnpj)}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {formatDate(item.dateCreated)}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {formatDate(item.dateLastUpdated)}
                        </td>
                        <td onClick={() => showOrderStatus(item)}>
                          {formatValue(item.totalOrder)}
                        </td>
                          {
                          (item.orderStatusId === 15 && role === 'Atendimento' && (
                            <td>
                              <Link to="#" onClick={() => showContractDialog(item)}>
                                {item.orderStatus}
                              </Link>
                            </td>
                          )) ||
                          (item.orderStatusId === 16 && role !== 'Atendimento' && (
                            <td>
                              <Link to="#" onClick={() => showOldSendContract(item)}>
                                {item.orderStatus}
                              </Link>
                            </td>
                          )) ||
                          (item.orderStatusId === 3 && (
                            <td onClick={() => showOrderStatus(item)}>
                              Aguardando análise de crédito
                            </td>
                          )) || (
                            <td onClick={() => showOrderStatus(item)}>
                              {item.orderStatus}
                            </td>
                          )
                        }

                        {item.orderCanCancel ? (
                          <td onClick={() => handleRemove(item)}>
                            <span
                              style={{
                                color: 'red',
                                textDecoration: 'underline'
                              }}
                            >
                              Cancelar pedido
                            </span>
                          </td>
                        ) : (
                          <td style={{ minWidth: '60px' }}></td>
                        )}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    {isLoading ? (
                      <td colSpan={10}>
                        <WrapperLogo>
                          <Loading />
                        </WrapperLogo>
                      </td>
                    ) : (
                      <td colSpan={10}> Nenhum registro encontrado! </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>

            <PaginationList
              paginate={paginate}
              currentPage={currentPage}
              total={total}
              perPage={PER_PAGE}
            />
            <br />
          </div>
        </WrapperTable>
      </Container>

      <Footer />

      <div className="contractModal">
        <Modal footer={null} title="" visible={contractModalVisible} onCancel={modalClose}>
          <ContentModalContrato>
                                    {contractOwner ? (
                                      <p>Pedido assumido pelo usuário {contractOwner} para envio deste contrato, deseja assumir?</p>
                                        
                                    ) : (
                                      <p>Esta ação permite que você seja o responsável pelo envio deste contrato, deseja continuar?</p>

                                    )}

            <div>
              <button onClick={() => modalOk()}>Sim</button>
              <button className='cancel' onClick={() => modalClose()}>Não</button>
            </div>
          </ContentModalContrato>
        </Modal>
      </div>

      <OrderModal visible={newOrder} onCancel={() => setNewOrder(false)} />

      <Modal visible={visible} onCancel={() => setVisible(false)} footer="">
        <ContentModal>
          <h3>Cancelar pedido</h3>

          <p
            style={{
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            Esta operação não poderá ser desfeita.
            <br />
            Deseja continuar?
          </p>

          <p>Para continuar o cancelamento preencha o motivo*:</p>

          <textarea
            style={{ width: '100%', minHeight: 100 }}
            onChange={handleChangeTextCancel}
          />

          <SelectSegmentButtons>
            <Button
              onClick={() => {
                handleSubmitCancel()
              }}
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                setVisible(false)
              }}
              style={{
                backgroundColor: 'GrayText'
              }}
            >
              Cancelar
            </Button>
          </SelectSegmentButtons>
        </ContentModal>
      </Modal>
    </>
  )
}

export default Orders
