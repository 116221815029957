/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  useEffect
} from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Radio, Select } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'

import { useToast } from '../../hooks/toast'
import { useAuth } from '../../hooks/auth'
import api from '../../services/api'
import { Container, Content, AnimationContainer, Wrapper } from './styles'
import Input from '../../components/Input'
import Button from '../../components/Button'
import getValidationErrors from '../../utils/getValidationErrors'
import { cpfMask } from '../../utils/cpfMask'
import { phoneMask } from '../../utils/phoneMask'
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'
import { cepMask } from '../../utils/cepMask'
import { cnpjMask } from 'utils/cnpjMask'
import iconTrash from '../../assets/icons/trash-2.svg'

interface IContact {
  companyId?: string
  cpf: string
  name: string
  email: string
  phone?: string
}

interface RegisterCompanyFormData {
  companyId?: number | string
  UserId: number
  cnpj: string
  companyName: string
  contactName: string
  financialContactName: string
  financialContactEmail: string
  cpf: string
  phone: string
  mail: string
  address: {
    zipCode: string
    street: string
    number: number
    district: string
    city: string
    state: string
    complement: string
  }
  quantityVehicules: number
  typeVehicule: number
  carRental: string
  contacts: IContact[]
  Signatario?:any
}

const RegisterCompany: React.FC = () => {
  const { infoClient, isCompany, orderId } = useParams<any>()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { userId } = useAuth()
  const { addToast } = useToast()

  const [cep, setCep] = useState('')
  const [hasVehicle, setHasVehicle] = useState(false)
  const [vehicleType, setVehicleType] = useState(0)
  const [street, setStreet] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [isCityEnabled, setCityEnabled] = useState(false)
  const [isStateEnabled, setStateEnabled] = useState(false)
  const [stateUser, setStateUser] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [company, setCompany] = useState<RegisterCompanyFormData>({} as any)
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [userIdCompany, setUserIdCompany] = useState(0)
  const [contacts, setContacts] = useState<IContact[]>([])
  const [validationErrors, setValidationErrors] = useState<{
    [key: number]: string
  }>({})

  const [statesBr, setStatesBr] = useState<any[]>([])
  const [locationStates, setLocationStates] = useState<any[]>([])
  const [citiesBr, setCitiesBr] = useState([])

  const inititalData = {
    companyName: company.companyName,
    contactName: company.contactName,
    cpf: !isEmpty(company) ? cpfMask(company.cpf) : '',
    mail: company.mail,
    phone: !isEmpty(company) ? phoneMask(company.phone) : '',
    address: {
      zipCode: !isEmpty(company.address) ? company.address.zipCode : '',
      street: !isEmpty(company.address) ? company.address.street : '',
      number: !isEmpty(company.address) ? company.address.number : '',
      district: !isEmpty(company.address) ? company.address.district : '',
      city: !isEmpty(company.address) ? company.address.city : '',
      state: !isEmpty(company.address) ? company.address.state : '',
      complement: !isEmpty(company.address) ? company.address.complement : ''
    },
    quantityVehicules: company.quantityVehicules,
    typeVehicule: company.typeVehicule,
    carRental: company.carRental,
    contacts: company.contacts && company.contacts,
    financialContactName: !isEmpty(company.financialContactName)
      ? company.financialContactName
      : '',
    financialContactEmail: !isEmpty(company.financialContactEmail)
      ? company.financialContactEmail
      : ''
  }

  useEffect(() => {
    if (!userId) {
      history.push('/')
    }

    if (infoClient) {
      const clearCnpj = infoClient
        .replace('_', '')
        .replace('/', '')
        .replace('-', '')
        .replace('.', '')
        .replace('.', '')

      setCnpj(clearCnpj)

      api
        .post('/signup/signUp-company', { cpfCnpj: infoClient })
        .then((res) => {
          const { userId } = res.data
          setUserIdCompany(userId)
          localStorage.setItem('@FleetSolutions:userIdCompany', userId)
        })

      try {
        api.get(`/serasa/${clearCnpj}`).then((response) => {
          const { data } = response

          if (data) {
            
            setCompany(data)
            
            setCpf(cpfMask(data.cpf))
            setPhone(phoneMask(data.phone))
            setCep(cepMask(data.address.zipCode))
            setVehicleType(data.typeVehicule)

            if (data.quantityVehicules > 0) {
              setHasVehicle(true)
            }
            if (data.address) {
              setStateUser(data.uf)
              setStreet(data.address.street)
              setDistrict(data.address.district)

              data.address.city
                ? setCity(data.address.city)
                : setCityEnabled(true)
            }

            if (data.contacts.length > 0) {
              setContacts(data.contacts)
            }
            else{
              setContacts([{ cpf: '', email: '', name: '' }])
            }

            // api
            //   .get(`/ceps?cep=${data.address.zipCode}`)
            //   .then((response: any) => {
            //     if (!response.data.erro) {
            //       response.data.uf
            //         ? setStateUser(response.data.uf)
            //         : setStateEnabled(true)
            //     }
            //   })
              

          } else {
            if (contacts.length === 0) {
              setContacts([{ cpf: '', email: '', name: '' }])
            }
            // addToast({
            //   title: 'Ocorreu um erro',
            //   type: 'error',
            //   description:
            //     'CNPJ não encontrado. Por favor, preencher os dados do cliente.'
            // })
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [infoClient])

  useEffect(() => {
    const fetchStates = async () => {
      try {
        // Fetch estados do IBGE
        const res = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        const data = await res.json();
        
        // Fetch estados da API
        const response = await api.get('location/state');
        setLocationStates(response.data);

        // Ordenar estados por nome
        const sortedData = data.sort((a: { nome: string }, b: { nome: any }) => a.nome.localeCompare(b.nome));
        setStatesBr(sortedData);

        // Verificar se customer e customer.address estão definidos
        if (company && company.address) {
          // Encontrar UF do cliente
          const ufName = response.data.find((item: { value: any }) => item.value === company.address.state)?.text;
          const ufCode = sortedData.find((state: { nome: any }) => state.nome === ufName)?.sigla;
          setStateUser(ufCode);
        }

      } catch (err) {
        console.error(err);
      }
    };

    if (statesBr.length === 0) {
      fetchStates();
    }
  }, [statesBr.length, company]);

  const updatedContacts = contacts.map((contact) => ({
    ...contact,
    cpf: contact.cpf.replace(/\D/g, '')
  }))

  const handleSubmit = useCallback(
    async (data: RegisterCompanyFormData) => {
      if (
        contacts.length > 0 &&
        (contacts.some((contact) => contact.name.trim() !== '') ||
          contacts.some((contact) => contact.email.trim() !== '') ||
          contacts.some((contact) => contact.cpf.trim() !== ''))
      ) {
        const errors = validateFields()

        if (Object.keys(errors).length === 0) {
          setValidationErrors({})
        } else {
          setValidationErrors(errors)
          return
        }
      }

      if (hasVehicle === true) {
        if (vehicleType === 0) {
          addToast({
            title: 'Atenção',
            type: 'error',
            description: 'Informe o tipo de veículo.'
          })

          return
        }

        if (data.quantityVehicules < 1) {
          addToast({
            title: 'Atenção',
            type: 'error',
            description: 'Informe a quantidade de veículos.'
          })

          return
        }

        if (vehicleType === 0 && data.carRental === '') {
          addToast({
            title: 'Atenção',
            type: 'error',
            description: 'Informe a locadora.'
          })

          return
        }
      }

      data.address.state = stateUser
      data.address.city = city

      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          companyName: Yup.string().required('Campo obrigatório'),
          contactName: Yup.string().required('Campo obrigatório'),
          cnpj: Yup.string().required('Campo obrigatório'),
          cpf: Yup.string()
            .max(14, 'Máximo 14 digitos')
            .min(14, 'CPF incompleto')
            .matches(/^\d{3}.\d{3}.\d{3}-\d{2}$/, 'Utilize ponto e traço')
            .required('CPF obrigatório'),
          mail: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string().required('Campo obrigatório'),
          quantityVehicules: Yup.number(),
          address: Yup.object().shape({
            street: Yup.string().required('Campo obrigatório'),
            zipCode: Yup.string()
              .required('Campo obrigatório')
              .min(8, 'CEP inválido')
              .max(9, 'CEP inválido'),
            number: Yup.number().required('Campo obrigatório'),
            district: Yup.string().required('Campo obrigatório'),
            city: Yup.string().required('Campo obrigatório'),
            state: Yup.string().required('Campo obrigatório')
          }),
          financialContactName: Yup.string().required('Campo obrigatório'),
          financialContactEmail: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        data.UserId = userIdCompany
        data.typeVehicule = vehicleType
        data.cpf = data.cpf.replace(/\D/g, '')
        data.phone = data.phone.replace(/\D/g, '')
        data.cnpj = data.cnpj.replace(/\D/g, '')
        data.address.zipCode = cep.replace(/\D/g, '')




        if (!hasVehicle) {
          data.quantityVehicules = 0
        }

        if (contacts.length > 0) {
          data.contacts = updatedContacts
          data.contacts = data.contacts.filter(obj => {
            return Object.values(obj).some(value => value !== "");
          });

          data.contacts.forEach((element, index) => {
            data.contacts[index].companyId =  data.contacts[index].companyId?.toString();
          })
        }

        if(data.Signatario){
          delete data.Signatario
        }
        

        if (isEmpty(company)) {
          await api
            .post('/company', data)
            .then(() => {
              addToast({
                title: 'Cadastro realizado!',
                type: 'success',
                description: 'Seu cadastro foi realizado com sucesso.'
              })

              dispatch(setTabs({ id: '2' }))
            })
            .catch((error) => {
              if (error.response.data.errors.Cpf) {
                addToast({
                  type: 'error',
                  title: 'Erro ao cadastrar!',
                  description: `${error.response.data.errors.Cpf[0]}`
                })
              } else {
                addToast({
                  type: 'error',
                  title: 'Erro ao cadastrar!',
                  description: 'Ocorreu um ao cadastrar'
                })
              }
            })
        } else {
          data.companyId = company.companyId?.toString()
          await api
            .put('/company', data)
            .then(() => {
              if (isCompany) {
                api
                  .put(`/orders/RetryCreditAnalysis`, {
                    orderId: Number(orderId),
                    userId: userIdCompany
                  })
                  .then(() => {
                    addToast({
                      title: 'Pedido concluído!',
                      type: 'success',
                      description: 'Seu pedido foi reenviado com sucesso.'
                    })

                    history.push(`/order-status/${orderId}`)
                  })
              } else {
                addToast({
                  title: 'Cadastro editado!',
                  type: 'success',
                  description: 'Seu cadastro foi editado com sucesso.'
                })

                dispatch(setTabs({ id: '2' }))
              }
            })
            .catch((error) => {
              addToast({
                type: 'error',
                title: 'Erro ao Cadastrar!',
                description: 'Ocorreu um ao cadastrar'
              })
            })
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
          addToast({
            type: 'error',
            title: 'Erro ao Cadastrar',
            description: 'Preencha os campos corretamente.'
          })
        }
      }
    },
    [
      contacts,
      hasVehicle,
      addToast,
      vehicleType,
      userId,
      history,
      company,
      cep,
      userIdCompany,
      stateUser,
      city
    ]
  )

  const handleKeyUpCep = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCep(cepMask(value))
    if (value.length === 9) {
      api
        .get(`/ceps?cep=${e.target.value}`)
        .then((response: any) => {
          if (!response.data.erro) {
            response.data.logradouro && setStreet(response.data.logradouro)

            setDistrict(response.data.bairro)
            response.data.localidade
              ? setCity(response.data.localidade)
              : setCityEnabled(true)
            response.data.uf
              ? setStateUser(response.data.uf)
              : setStateEnabled(true)
          }
        })
        .catch((error: any) => {
          setStreet('')
          setDistrict('')
          setCity('')
          setStateUser('')
          setCityEnabled(true)
          setStateEnabled(true)
          addToast({
            title: 'Campo obrigatório',
            type: 'error',
            description: 'Endereço não encontrado'
          })
        })
    }
  }

  const handleChangeState = (value: string) => {
    setStateUser(value)
    setCity('')
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    fetch(url)
      .then((res) =>
        res.json().then(function (data) {
          setCitiesBr(data)
        })
      )
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCityChange = (value: string) => {
    console.log(value)
    setCity(value)
  }

  const handleFormatCpf = (item: any) => {
    setCpf(cpfMask(item.target.value))
  }

  const handleFormatPhone = (item: any) => {
    setPhone(phoneMask(item.target.value))
  }

  const handleKeyUpStreet = (item: any) => {
    setStreet(item.target.value)
  }

  const handleKeyUpDistrict = (item: any) => {
    setDistrict(item.target.value)
  }

  const validateFields = () => {
    const errors: { [key: number]: string } = {}

    contacts.forEach((contact: IContact, index: number) => {
      if (!contact.name || !contact.cpf || !contact.email) {
        errors[index] =
          'Todos os campos informados em Signatários são obrigatórios!'
      }
    })

    return errors
  }

  const handleAddContacts = () => {
    const errors = validateFields()
    setContacts([...contacts, { cpf: '', email: '', name: '' }])
  }

  const handleContactChange = (
    index: number,
    field: keyof IContact,
    value: string
  ) => {
    const updatedContacts = [...contacts]
    updatedContacts[index] = {
      ...updatedContacts[index],
      [field]: field === 'cpf' ? cpfMask(value) : value
    }
    setContacts(updatedContacts)
  }

  const handleDeleteContact = (indexToDelete: number) => {
    const errors = validateFields()
    if (contacts.length > 0) {
      const updatedContacts = [...contacts]
      updatedContacts.splice(indexToDelete, 1)
      if(updatedContacts.length === 0){
        setContacts([...updatedContacts, { cpf: '', email: '', name: '' }])
      }
      else{
        setContacts(updatedContacts)
      }
    }
  }

  const handleCreateContacts = (contact: IContact, index: number) => {
    
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '850',
          flexWrap: 'wrap'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '850',
            justifyContent: 'space-between'
          }}
        >
          <Input
            placeholder="Nome *"
            id={`Signatario.contactName${index}`}
            name={`Signatario.contactName${index}`}
            type="text"
            value={contact.name}
            onChange={(e) => handleContactChange(index, 'name', e.target.value)}
          />
          <Input
            placeholder="CPF *"
            id={`Signatario.cpf${index}`}
            name={`Signatario.cpf${index}`}
            type="text"
            maxLength={14}
            value={contact.cpf}
            onChange={(e) => handleContactChange(index, 'cpf', e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '850',
            justifyContent: 'space-between'
          }}
        >
          <Input
            placeholder="Email *"
            id={`Signatario.email${index}`}
            name={`Signatario.email${index}`}
            type="email"
            value={contact.email}
            onChange={(e) =>
              handleContactChange(index, 'email', e.target.value)
            }
          />
          <div>
            <a style={{ color: '#236fbc' }} onClick={handleAddContacts}>
              + Adicionar novo signatário
            </a>
            <a
              style={{ marginLeft: '200' }}
              onClick={() => handleDeleteContact(index)}
            >
              <img src={iconTrash} alt="Deletar" />
            </a>
          </div>
        </div>
        {validationErrors[index] && (
          <div style={{ color: 'red' }}>{validationErrors[index]}</div>
        )}
      </div>
    )
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h1>CADASTRO DE PESSOA JURÍDICA</h1>
            <p>
              Preencha o cadastro abaixo para realizar a reserva do veículo:
            </p>
            <span className='required-infos'>* Campos obrigatórios</span>
            <Form
              initialData={inititalData}
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <h2>Dados da empresa</h2>
              <Input
                placeholder="CNPJ *"
                id="cnpj"
                name="cnpj"
                type="text"
                maxLength={18}
                defaultValue={cnpjMask(cnpj)}
              />
              <Input
                placeholder="Razão social *"
                id="companyName"
                name="companyName"
                type="text"
              />
              <h2>Contato da Empresa</h2>
              <Input
                placeholder="Nome *"
                id="contactName"
                name="contactName"
                type="text"
              />
              <Input
                placeholder="CPF *"
                id="cpf"
                name="cpf"
                type="text"
                maxLength={14}
                value={cpf}
                onChange={handleFormatCpf}
              />
              <Input placeholder="Email *" id="mail" name="mail" type="email" />
              <Input
                placeholder="Telefone *"
                id="phone"
                name="phone"
                type="tel"
                onChange={handleFormatPhone}
                maxLength={15}
                defaultValue={phone}
              />
              <h2>Contato Financeiro</h2>
              <Input
                placeholder="Nome *"
                id="financialContactName"
                name="financialContactName"
                type="text"
              />
              <Input
                placeholder="Email *"
                id="financialContactEmail"
                name="financialContactEmail"
                type="email"
              />
              <h2>Signatários</h2>
              {contacts.map((contact, index) => {
                return handleCreateContacts(contact, index)
              })}
              <h2>Endereço</h2>
              <Input
              placeholder="CEP *"
              id="zipcode"
              name="address.zipCode"
              type="text"
              maxLength={9}
              onChange={handleKeyUpCep}
              value={cep}
            />
              <Input
                placeholder="Logradouro *"
                id="street"
                name="address.street"
                type="text"
                defaultValue={street}
                maxLength={80}
                onChange={handleKeyUpStreet}
              />
              <Input
                placeholder="Número *"
                id="text"
                name="address.number"
                type="number"
                maxLength={5}
              />
              <Input
                placeholder="Bairro *"
                id="district"
                name="address.district"
                type="text"
                defaultValue={district}
                onChange={handleKeyUpDistrict}
              />
              <Input
                placeholder="Complemento"
                id="complement"
                name="address.complement"
                type="text"
                maxLength={40}
              />


                <div className="custom-selects address-selects">
                    <Select
                      size="large"
                      placeholder="Estado *"
                      onChange={(value) => handleChangeState(value)}
                      value={stateUser}
                      disabled={!isStateEnabled}
                    >
                      {statesBr.map((item: any) => {
                        return (
                          <option key={item.sigla} value={item.sigla}>
                            {item.sigla}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                  <div className="custom-selects address-selects">
      
                    <Select
                      size="large"
                      placeholder="Cidade *"
                      onChange={(value) => handleCityChange(value)}
                      value={city}
                      disabled={!isCityEnabled}
                    >
                      {citiesBr.map((item: any) => {
                        return (
                          <option key={item.nome} value={item.nome}>
                            {item.nome}
                          </option>
                        )
                      })}
                    </Select>
                  </div>

              <Wrapper>
                <p>Possui veículo?</p>
                <Radio.Group
                  style={{ width: 'auto', marginLeft: 20 }}
                  value={hasVehicle}
                >
                  <Radio
                    value={true}
                    onClick={() => {
                      setHasVehicle(true)
                      setVehicleType(1)
                    }}
                  >
                    Sim
                  </Radio>
                  <Radio value={false} onClick={() => setHasVehicle(false)}>
                    Não
                  </Radio>
                </Radio.Group>
              </Wrapper>
              {hasVehicle === true && (
                <Wrapper>
                  <p>Tipo:</p>
                  <Radio.Group
                    style={{ width: 'auto', marginLeft: 20 }}
                    value={vehicleType}
                  >
                    <Radio
                      value={vehicleType === 1 && vehicleType}
                      onClick={() => setVehicleType(1)}
                    >
                      Próprio
                    </Radio>
                    <Radio
                      value={vehicleType === 2 && vehicleType}
                      onClick={() => setVehicleType(2)}
                    >
                      Alugado
                    </Radio>
                  </Radio.Group>
                </Wrapper>
              )}
              {hasVehicle === true && (
                <Input
                  placeholder="Quantidade *"
                  id="quantityVehicules"
                  name="quantityVehicules"
                  type="number"
                />
              )}
              {hasVehicle === true &&
                (vehicleType === 0 || vehicleType === 1) && <div></div>}
              {vehicleType === 2 && hasVehicle === true && (
                <Input
                  placeholder="Qual locadora? *"
                  id="carRental"
                  name="carRental"
                  type="text"
                  required
                />
              )}

              <div className="wrapper-btn">
                <Button type="submit">Concluir cadastro</Button>
              </div>
            </Form>
          </AnimationContainer>
        </Content>
      </Container>
    </>
  )
}

export default RegisterCompany
