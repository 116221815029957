/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Upload } from 'antd';
import { AiOutlineUpload } from 'react-icons/ai';
import { UploadChangeParam } from 'antd/lib/upload';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Container, Content, WrapperDoc } from './styles';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { setTabs } from '../../store/modules/tabsRegisterUser/actions';
import Loading from '../../components/Loading';
import { info } from './ModalInfo';
import { defaultBackendUrl } from 'services/api';
import cnh from '../../assets/logo/cnh.png';
import cnhverso from '../../assets/logo/cnh_verso.png';
import { PDFDocument } from 'pdf-lib';


const UploadPessoaFisica = () => {
    const clientId = Number(localStorage.getItem('@FleetSolutions:userIdPf'));

    const { addToast } = useToast();
    const dispatch = useDispatch();
    const history = useHistory();
    const { orderId, infoClient } = useParams<any>();
    const [typeDoc, setTypeDoc] = useState('');
    const [allSendDocs, setAllSendDocs] = useState<string[]>([]);
    const [order, setOrder] = useState<any>(null);

    const [disabledDocCnhBack, setDisabledDocCnhBack] = useState(true);
    const [disabledDocAdd, setDisabledDocAdd] = useState(true);
    const [disabledDocInco, setDisabledDocInco] = useState(true);

    const [loadingDocCnh, setLoadingDocCnh] = useState(false);
    const [loadingDocCnhBack, setLoadingDocCnhBack] = useState(false);
    const [loadingDocAdd, setLoadingDocAdd] = useState(false);
    const [loadingDocInco, setLoadingDocInco] = useState(false);

    const [orderStatus, setOrderStatus] = useState();
    const [reprovedDocs, setReprovedDocs] = useState<any>([]);
    const [blockButtonResend, setBlockButtonResend] = useState(false);
    const [blockButtonSend, setBlockButtonSend] = useState(true);

    


    const translateDocName = (docName: string) => {
        if (docName === 'Cnh') {
            return 'CNH - frente';
        } else if (docName === 'CnhBack') {
            return 'CNH - verso';
        } else if (docName === 'ProofOfIncome') {
            return 'Comprovante de renda';
        } else {
            return 'Comprovante de residência';
        }
    };

    const props = {
        name: 'file',
        multiple: false,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        beforeUpload: async (file:File) => {
      
          if (file.type === 'application/pdf') {
            const fileBuffer = await file.arrayBuffer();
            const pdfDoc = await PDFDocument.load(fileBuffer);
      
            if (pdfDoc.getPages().length >= 41) {
              addToast({
                title: 'Falha ao enviar documento',
                type: 'error',
                description: `O documento ${file.name} excedeu a quantidade máxima de páginas. Por favor, reenvie o documento com até 20 páginas.`,
              });
              return false;
            }
          }
      
        },
        onChange: async (info:UploadChangeParam) => {
            
          if (info.file.originFileObj && info.file.status as string !== 'status') {
            if (info.file.type) info.file.name = `${translateDocName(typeDoc)}.${info.file.type.split('/')[1]}`;
            handleFormatDocUpload(info);
          }
        },
      };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (orderId && orderId !== undefined) {
            api.get(`orders/${orderId}`).then(async (response: any) => {
                const { status, userId } = response.data;
                setOrderStatus(status);
                if (status === 14) {
                    const resErrors = await api.get(`/upload/errors?userId=${userId}&orderId=${orderId}`);
                    if (resErrors.data.errors.length > 0) {
                        let docs = resErrors.data.errors.map((item: { typeDocument: any }) => item.typeDocument);
                        setDisabledDocCnhBack(false)
                        setDisabledDocAdd(false)
                        setDisabledDocCnhBack(false)
                        setDisabledDocInco(false)
                        setReprovedDocs(docs);
                    }
                    setOrder(response.data);
                }
            });
        }
    }, []);

    const handleFormatDocUpload = async (image: any) => {
        
        const formData = new FormData();

        if (image.file.size > 5000000 && image.file.status !== 'uploading') {
            return addToast({
                title: 'Falha ao enviar documento',
                type: 'error',
                description: 'O tamanho do arquivo é maior que 5 mb',
            });
        }
        else if(image.file.type === "" && image.file.status === 'error'){
            return addToast({
                title: 'Falha ao enviar documento',
                type: 'error',
                description: 'O arquivo não tem uma extensão válida.',
            });
        } else {
            if (image.file.status === 'done' || image.file.status === 'error') {
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        Accept: 'text/plain',
                    },
                };

                formData.append('uploadRequest', image.file.originFileObj);

                if (image.file.name.indexOf('CNH - frente') !== -1) {
                    await sendCnh(config, formData);
                } else if (image.file.name.indexOf('CNH - verso') !== -1) {
                    await sendCnhBack(config, formData);
                } else if (image.file.name.indexOf('Comprovante de renda') !== -1) {
                    await sendInco(config, formData);
                } else {
                    await sendAdd(config, formData);
                }
            }
        }
    };

    const handleSendDocWithParams = () => {
        return orderId ? `${infoClient}&onlySave=true` : clientId;
    };

    const sendCnh = async (config: any, formData: any) => {
        
        setLoadingDocCnh(true);
        try {
            await api.post(`/upload/UploadCnhAsync?userId=${handleSendDocWithParams()}`, formData, config).then(() => {
                setAllSendDocs([...allSendDocs, 'cnh']);
                setDisabledDocCnhBack(false);
                setLoadingDocCnh(false);
            });
        } catch (error) {
            addToast({
                title: 'Erro ao fazer upload',
                description: 'Ocorreu um erro ao fazer upload da CNH',
                type: 'error',
            });
            setLoadingDocCnh(false);
        }
    };

    const sendCnhBack = async (config: any, formData: any) => {
        setLoadingDocCnhBack(true);
        try {
            await api.post(`/upload/UploadCnhBackAsync?userId=${handleSendDocWithParams()}`, formData, config).then(() => {
                setAllSendDocs([...allSendDocs, 'cnhBack']);
                setDisabledDocAdd(false);
                setLoadingDocCnhBack(false);
            });
        } catch (error) {
            addToast({
                title: 'Erro ao fazer upload',
                description: 'Ocorreu um erro ao fazer upload do verso da CNH',
                type: 'error',
            });
            setLoadingDocCnhBack(false);
        }
    };

    const sendInco = async (config: any, formData: any) => {
        setLoadingDocInco(true);
        try {
            await api.post(`/upload/UploadProofOfIncomeAsync?userId=${handleSendDocWithParams()}`, formData, config).then((response: any) => {
                setAllSendDocs([...allSendDocs, 'income']);
                setLoadingDocInco(false);
            });
        } catch (error) {
            addToast({
                title: 'Erro ao fazer upload',
                description: 'Ocorreu um erro ao fazer upload do comprovante de renda.',
                type: 'error',
            });
            setLoadingDocInco(false);
        }
    };

    const sendAdd = async (config: any, formData: any) => {
        setLoadingDocAdd(true);
        try {
            await api.post(`/upload/UploadProofOfAddressAsync?userId=${handleSendDocWithParams()}`, formData, config).then((response: any) => {
                setAllSendDocs([...allSendDocs, 'address']);
                setDisabledDocInco(false);
                setLoadingDocAdd(false);
            });
        } catch (error) {
            addToast({
                title: 'Erro ao fazer upload',
                description: 'Ocorreu um erro ao fazer upload do comprovante de endereço',
                type: 'error',
            });
            setLoadingDocAdd(false);
        }
    };

    const handleContinueOrder = () => {
        
        if (allSendDocs.length < 4) {
            addToast({
                type: 'error',
                title: 'Documento pendente',
                description: 'Para continuar o pedido é necessário enviar todos os documentos.',
            });
            return;
        }
        localStorage.setItem('@FleetSolutions:uploadFileDocumentoUser', 'false');
        dispatch(setTabs({ id: '2' }));
    };

    const showDocument = (type: any) => {
        return reprovedDocs?.includes(type);
    };

    const handleChangeResendUploadFiles = useCallback(async () => {
        if (allSendDocs.length === 0 || allSendDocs.length < reprovedDocs.length) {
            addToast({
                type: 'error',
                title: 'Documento pendente',
                description: 'Para continuar o pedido é necessário enviar todos os documentos.',
            });
            return;
        }

        if (orderStatus === 8) {
            dispatch(setTabs({ id: '2' }));
        } else {
            setBlockButtonResend(true)
            const typePaymentChoise = Number(localStorage.getItem('@FleetSolutions:typePaymentChoise'));
            // if (typePaymentChoise) {
            //     if (typePaymentChoise === 2) {
            //         await api.delete(`orderstatus/${orderId}`);

            //         await api.post(`orderstatus`, {
            //             orderId: Number(orderId),
            //             status: 1,
            //         });
            //     } else if (typePaymentChoise === 1) {
            //         await api.post(`orderstatus`, {
            //             orderId: Number(orderId),
            //             status: 20,
            //         });
            //     }
            // } else {
            //     await api.delete(`orderstatus/${orderId}`);

            //     await api.post(`orderstatus`, {
            //         orderId: Number(orderId),
            //         status: 1,
            //     });   
            // }
            const retryAuthenticity =  await api.put(`orderstatus/RetryAuthenticity/${orderId}`);   

            if(retryAuthenticity){
                history.push(`/order-status-pf/${orderId}`);
            }
        }
    }, [order, allSendDocs]);

    return (
        <Container>
            <h1 className='title'>Carregar documentos</h1>
            <p className='description'>Por favor, inclua os documentos abaixo do cliente para seguirmos a validação do cadastro.</p>
            <Content>
                {reprovedDocs?.length === 0 || showDocument(105) ? (
                    <WrapperDoc onClick={() => setTypeDoc('Cnh')} onDrop={() => setTypeDoc('Cnh')}>
                        <div className='left'>
                            <p className='name-file'>
                                CNH FRENTE
                                <span>(Obrigatório)</span>
                            </p>
                            <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                            <p>
                                <a className='know-more' href='#' onClick={() => info('CNH FRENTE', '', 'https://signanddrive.s3.sa-east-1.amazonaws.com/Imagens/cnh.png')}>
                                    (saiba mais)
                                </a>
                            </p>
                        </div>

                        <div className='right'>
                            <Upload className='upload' {...props}>
                                <div style={loadingDocCnh ? { display: '' } : { display: 'none' }}>
                                    <Loading text={'Carregando CNH'} />
                                </div>

                                {!loadingDocCnh && (
                                    <Button className='btn-upload'>
                                        {' '}
                                        <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                                    </Button>
                                )}
                            </Upload>
                        </div>
                    </WrapperDoc>
                ) : null}

                {reprovedDocs?.length === 0 || showDocument(121) ? (
                    <WrapperDoc onClick={() => setTypeDoc('CnhBack')} onDrop={() => setTypeDoc('CnhBack')} style={disabledDocCnhBack ? { opacity: 0.3 } : { opacity: 1 }}>
                        <div className='left'>
                            <p className='name-file'>
                                CNH VERSO <span>(Obrigatório)</span>
                            </p>
                            <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                            <p>
                                <a className='know-more' href='#' onClick={() => info('CNH VERSO', '', 'https://signanddrive.s3.sa-east-1.amazonaws.com/Imagens/cnh_verso.png', 700)}>
                                    (saiba mais)
                                </a>
                            </p>
                        </div>

                        <div className='right'>
                            <Upload disabled={disabledDocCnhBack} className='upload' {...props}>
                                <div style={loadingDocCnhBack ? { display: '' } : { display: 'none' }}>
                                    <Loading text={'Carregando CNH Verso'} />
                                </div>
                                {!loadingDocCnhBack && (
                                    <Button className='btn-upload'>
                                        {' '}
                                        <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                                    </Button>
                                )}
                            </Upload>
                        </div>
                    </WrapperDoc>
                ) : null}

                {reprovedDocs?.length === 0 || showDocument(5) ? (
                    <WrapperDoc onClick={() => setTypeDoc('ProofOfAddress')} onDrop={() => setTypeDoc('ProofOfAddress')} style={disabledDocAdd ? { opacity: 0.3 } : { opacity: 1 }}>
                        <div className='left'>
                            <p className='name-file'>
                                Comprovante de endereço <span>(Obrigatório)</span>
                            </p>
                            <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                            <p>
                                <a
                                    className='know-more'
                                    href='#'
                                    onClick={() =>
                                        info(
                                            'Comprovante de endereço',
                                            'Comprovante de endereço, cópia de um dos documentos a seguir citados, desde que emitidos há no máximo 60 dias em nome do cliente ou de pessoas que tenham grau de parentesco (desde que comprovado): conta de luz, telefone (fixo ou móvel), gás, condomínio, contrato de locação, imposto predial, TV a cabo, provedor de internet ou de fatura de cartão de crédito (consumo).'
                                        )
                                    }
                                >
                                    (saiba mais)
                                </a>
                            </p>
                        </div>
                        <div className='right'>
                            <Upload disabled={disabledDocAdd} className='upload' {...props}>
                                <div style={loadingDocAdd ? { display: '' } : { display: 'none' }}>
                                    <Loading text={'Carregando comprovante de endereço'} />
                                </div>
                                {!loadingDocAdd && (
                                    <Button className='btn-upload'>
                                        {' '}
                                        <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                                    </Button>
                                )}
                            </Upload>
                        </div>
                    </WrapperDoc>
                ) : null}

                {reprovedDocs?.length === 0 || showDocument(600) ? (
                    <WrapperDoc onClick={() => setTypeDoc('ProofOfIncome')} onDrop={() => setTypeDoc('ProofOfIncome')} style={disabledDocInco ? { opacity: 0.3 } : { opacity: 1 }}>
                        <div className='left'>
                            <p className='name-file'>
                                Comprovante de renda <span>(Obrigatório)</span>
                            </p>
                            <p>Arquivos (JPG, PNG ou PDF) até 5 mb</p>
                            <p>
                                <a
                                    className='know-more'
                                    href='#'
                                    onClick={() =>
                                        info(
                                            'Comprovante de renda',
                                            'Cliente CLT: Holerite emitido há no máximo 60 dias. Para os casos de clientes com renda variável (horas extras, comissões e prêmios), é necessária a apresentação dos últimos 3 comprovantes de renda, sendo considerada a média. Isto evitará que picos de remuneração sejam considerados como habitual.<br/><br/>Proprietário Empresa: IRPF com o recibo de entrega da Declaração, do exercício atual, conforme calendário da Receita Federal (extrato detalhado do documento), ou extrato bancário dos últimos 90 dias.<br/><br/>Autônomo / Profissional liberal: IRPF com o recibo de entrega da Declaração, do exercício atual, conforme calendário da Receita Federal (extrato detalhado do documento), ou extrato bancário dos últimos 90 dias.'
                                        )
                                    }
                                >
                                    (saiba mais)
                                </a>
                            </p>
                        </div>
                        <div className='right'>
                            <Upload disabled={disabledDocInco} className='upload' {...props}>
                                <div style={loadingDocInco ? { display: '' } : { display: 'none' }}>
                                    <Loading text={'Carregando comprovante de renda'} />
                                </div>
                                {!loadingDocInco && (
                                    <Button className='btn-upload'>
                                        {' '}
                                        <AiOutlineUpload size={17} /> CLIQUE PARA CARREGAR
                                    </Button>
                                )}
                            </Upload>
                        </div>
                    </WrapperDoc>
                ) : null}
            </Content>

            <div className='buttons'>
                <div className='wrapper-button'>
                    <Button className='btn-save' style={{ display: 'none' }}>
                        SALVAR PROPOSTA
                    </Button>

                    {orderId ? (
                        <Button disabled={blockButtonResend} className='btn-continue' onClick={handleChangeResendUploadFiles}>
                            Reenviar
                        </Button>
                    ) : (
                        <Button className='btn-continue' onClick={handleContinueOrder}>
                            CONTINUAR
                        </Button>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default UploadPessoaFisica;
