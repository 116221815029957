import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 40px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;
  border: 2px solid #236fbc;
  border-radius: 16px;
  background: #ffffff;
  padding: 25px;
  color: #000000;

  h2 {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #0668b4;
  }
`

export const WrapperLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column !important;
  border-bottom: 1px solid grey;
  margin-bottom: 30px;
  flex-wrap: wrap;

  span {
    font-weight: bold;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      margin-bottom: 20px;
    }

    p {
      font-size: 13px !important;
    }

    & > p {
      width: auto;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row !important;
  }

  .wrapper-img {
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 25px;
    }
  }

  .image-car {
    width: 200px;
  }
`

export const Contact = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;

  .data-company {
    display: flex;
    flex-direction: column;
    width: 35%;

    .wrapper-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .title-description {
        margin: 0 !important;
        font-weight: bold;
      }
    }
  }

  .data-contact {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 12px;

    .wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .wrapper-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .title-description {
        margin: 0 !important;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 810px) {
    flex-direction: column;

    .data-company {
      .wrapper-info {
        flex-direction: column;
      }
    }

    .data-contact {
      .wrapper-info {
        flex-direction: column;
      }
    }
  }
`

export const OrderInfo = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;

  span {
    font-weight: bold;
  }

  .data-order {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-bottom: 12px;

    .wrapper-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .btn-resend-doc {
      width: 180px;
      background-color: #0668b4;
      color: #fff;
      border: none;
      padding: 7px;
      border-radius: 8px;
      font-size: 12px;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.01);
      }
    }
  }

  .note {
    display: flex;
    flex-direction: column;
    width: 60%;
    .doc-item{
      margin-bottom:15px;
    }
  }

  .btn-resend-doc {
    width: 180px;
    background-color: #0668b4;
    color: #fff;
    border: none;
    padding: 7px;
    border-radius: 8px;
    font-size: 12px;
    transition: transform 0.2s ease;
  }

  .btn-resend-doc:hover {
    transform: scale(1.01);
  }
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const ModalComponent = styled.div`
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

  @media (min-width: 425px) {
    width: 400px;
  }

  h2 {
    font-size: 24px;
    color: #0c5faf;
    font-weight: 600;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: column;
      width: 80%;

      p {
        font-size: 14px;
        text-align: left;

        &:first-child {
          font-weight: 700;
          margin-bottom: 15px;
        }
      }
    }

    &:last-child {
      width: 90%;
      margin-top: 30px;

      button {
        width: 48%;
        border: none;
        color: white;
        border-radius: 24px;
        border: 2px solid #236fbc;
        font-size: 14px;
        height: 38px;

        &:first-child {
          background-color: white;
          color: #236fbc;
        }

        &:last-child {
          background-color: #236fbc;
        }
      }
    }
  }
`

export const WrapperStep = styled.div`
  width: 100%;
  flex-direction: column !important;
  height: 150px;
  flex-wrap: wrap;
`


export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #3c505a;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  button {
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    background: #0c5faf;
    margin: 10px;
    color: white;
    border: none;
    height: 30px;
    padding: 4px 14px;
    &.cancel{
    background-color:#868686}
  }
`